import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import {
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownContent,
  DropdownMenuItem,
  DropdownMenu,
  Badge,
  PatientNameStd,
  IconPencil,
} from "@herohealthsoftware/ui";
import { DateTime } from "luxon";
import { type AppointmentDto } from "@herohealthsoftware/api-sdk";
import * as Routes from "../../../../routes";
import { translate } from "../../../../lib/i18n";

export type Person = {
  firstName: string;
  lastName: string;
  age: number;
  visits: number;
  progress: number;
  status: "relationship" | "complicated" | "single";
  subRows?: Person[];
};

export const columns: ColumnDef<AppointmentDto>[] = [
  {
    accessorKey: "confirmed_at",
    header: translate(
      "admin.appointments.tables.appointments.headers.confirmedAt"
    ),
    enableColumnFilter: false,
    cell: ({ row }) => {
      return (
        <div>{`${DateTime.fromISO(row.getValue("confirmed_at")).toFormat(
          "dd-MMM-yyyy HH:mm"
        )}`}</div>
      );
    },
  },
  {
    accessorKey: "start_time",
    header: translate(
      "admin.appointments.tables.appointments.headers.startTime"
    ),
    enableColumnFilter: false,
    cell: ({ row }) => {
      return (
        <div>{`${DateTime.fromISO(row.getValue("start_time")).toFormat(
          "dd-MMM-yyyy HH:mm"
        )}`}</div>
      );
    },
  },
  {
    accessorKey: "patient",
    header: translate("admin.appointments.tables.appointments.headers.patient"),
    enableSorting: false,
    cell: ({ row }) => {
      const patient = row.getValue("patient") as AppointmentDto["patient"];
      return (
        <PatientNameStd
          title={patient.title}
          firstName={patient.first_name}
          lastName={patient.last_name}
        />
      );
    },
  },
  {
    accessorKey: "practitioner",
    header: translate(
      "admin.appointments.tables.appointments.headers.practitioner"
    ),
    enableSorting: false,
    cell: ({ row }) => {
      const practitioner = row.getValue(
        "practitioner"
      ) as AppointmentDto["practitioner"];
      return (
        <PatientNameStd
          title={practitioner.title}
          firstName={practitioner.first_name}
          lastName={practitioner.last_name}
        />
      );
    },
  },
  {
    accessorKey: "appointment_template_name",
    header: translate("admin.appointments.tables.appointments.headers.service"),
    enableSorting: false,
  },
  {
    accessorKey: "location_name",
    header: translate(
      "admin.appointments.tables.appointments.headers.location"
    ),
    enableSorting: false,
  },
  {
    accessorKey: "appointment_source",
    header: translate("admin.appointments.tables.appointments.headers.source"),
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ row }) => {
      switch (row.getValue("appointment_source")) {
        case "EMIS":
          return <>{translate("common.EMIS")}</>;
        case "Hero admin led":
          return <>{translate("common.admin")}</>;
        default:
          return <>{translate("common.online")}</>;
      }
    },
  },
  {
    accessorKey: "cancelled_at",
    header: translate("admin.appointments.tables.appointments.headers.status"),
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ row }) => {
      return (
        <Badge
          colour={row.getValue("cancelled_at") === null ? "neutral" : "red"}
          size="sm"
        >
          {row.getValue("cancelled_at")
            ? translate("base.cancelled")
            : translate("base.confirmed")}
        </Badge>
      );
    },
  },
  {
    id: "actions",
    header: translate("admin.appointments.tables.appointments.headers.actions"),
    cell: ({ row }) => {
      return (
        <Dropdown>
          <DropdownTrigger asChild>
            <Button
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
              }}
              size="xs"
              variant="white"
              className="-herolib-whitespace-nowrap"
            >
              {translate(
                "admin.appointments.tables.appointments.actions.openMenu"
              )}
            </Button>
          </DropdownTrigger>
          <DropdownContent align="start">
            <DropdownMenu>
              <DropdownMenuItem
                prepend={<IconPencil />}
                onClick={(e) => {
                  e.stopPropagation();
                  window.location.href = Routes.admin_edit_appointment_path({
                    id: row.original.id,
                  });
                }}
              >
                {translate(
                  "admin.appointments.tables.appointments.actions.edit"
                )}
              </DropdownMenuItem>
            </DropdownMenu>
          </DropdownContent>
        </Dropdown>
      );
    },
  },
];
