import { useEffect, useRef } from "react";

export default function useOutsideClick(onOutsideClick: () => void) {
  const outsideClickRef = useRef(null);

  const onDocumentClick = (event) => {
    if (
      outsideClickRef.current &&
      !outsideClickRef.current.contains(event.target)
    ) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onDocumentClick);

    return () => {
      document.removeEventListener("mousedown", onDocumentClick);
    };
  });

  return outsideClickRef;
}
