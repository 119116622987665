import { IconExternalLink, Link, ThemeProvider } from "@herohealthsoftware/ui";
import React, { useState } from "react";
import { StripeSearchType } from "../../../../hooks/useStripeSearch";
import { translate } from "../../../../lib/i18n";
import { StripeSettings } from "../../../../lib/types";
import Menu, { MenuOptions } from "../../../atoms/Menu";
import MembershipPoliciesSettings from "./MembershipPoliciesSettings";
import MembershipSchemesSettings from "./MembershipSchemesSettings";
import MembersSettings from "./MembersSettings";
import ResourceTable from "./ResourceTable";
import StripePartnerSettings from "./StripePartnerSettings";

type SettingsSection =
  | StripeSearchType
  | "settings"
  | "policies"
  | "members"
  | "schemes";

type StripeSettingsIndexProps = {
  open: [SettingsSection, number];
  settings: StripeSettings;
};

export default function SettingsIndex(props: StripeSettingsIndexProps) {
  const [defaultSection, defaultResourceId] = props.open;

  const [activeSection, setActiveSection] =
    useState<SettingsSection>(defaultSection);
  const [loading, setLoading] = useState(true);

  const handleChangeActiveSection = (section: SettingsSection) => {
    setLoading(true);
    setActiveSection(section);
  };

  const sections: MenuOptions = [
    {
      heading: translate("partners.stripe.billing"),
      sections: [
        {
          name: "invoice",
          label: translate("partners.stripe.invoices"),
        },
        {
          name: "product",
          label: translate("partners.stripe.products"),
        },
        {
          name: "settings",
          label: translate("partners.stripe.settings"),
        },
      ],
    },
  ];

  if (props.settings.show_memberships) {
    sections.push({
      heading: translate("partners.stripe.memberships"),
      sections: [
        {
          name: "policies",
          label: translate("partners.stripe.policies"),
        },
        {
          name: "members",
          label: translate("partners.stripe.members"),
        },
        {
          name: "schemes",
          label: translate("partners.stripe.schemes"),
        },
      ],
    });
  }

  return (
    <ThemeProvider>
      <div>
        <h1 className="text-2xl leading-8 font-bold text-hero-blue-700">
          {translate("partners.stripe.stripe")}
        </h1>

        <Link variant="primary">
          <a
            href={props.settings.dashboard_url}
            target="_blank"
            className="flex items-center gap-1 no-underline mb-5 w-fit"
          >
            <span>{translate("partners.stripe.goToStripeDashboard")}</span>
            <div className="h-4 w-4 [&_path]:fill-hero-primary-700">
              <IconExternalLink />
            </div>
          </a>
        </Link>

        <div className="flex items-start gap-4">
          <Menu
            selected={activeSection}
            options={sections}
            onClick={handleChangeActiveSection}
            className="w-48"
          />

          {activeSection === "settings" && (
            <StripePartnerSettings settings={props.settings} />
          )}

          {(activeSection === "invoice" || activeSection === "product") && (
            <ResourceTable
              resource={activeSection}
              loading={loading}
              setLoading={setLoading}
              stripe_dashboard_url={props.settings.dashboard_url}
            />
          )}

          {activeSection === "policies" && (
            <MembershipPoliciesSettings
              selected={
                defaultSection === "policies" ? defaultResourceId : undefined
              }
              loading={loading}
              setLoading={setLoading}
              settings={props.settings}
            />
          )}

          {activeSection === "members" && (
            <MembersSettings
              loading={loading}
              setLoading={setLoading}
              settings={props.settings}
            />
          )}

          {activeSection === "schemes" && (
            <MembershipSchemesSettings
              loading={loading}
              setLoading={setLoading}
              settings={props.settings}
            />
          )}
        </div>
      </div>
    </ThemeProvider>
  );
}
