import { IconArrowNarrowRight } from "@herohealthsoftware/ui";
import React from "react";
import { invoiceTransitionTimes } from "../../../lib/invoice";
import { formatPence } from "../../../lib/money";
import { Invoice } from "../../../lib/types";
import StatusBadge from "./StatusBadge";

type InvoiceListItemProps = {
  invoice: Invoice;
  action?: React.ReactNode;
  handleInvoiceClick: (invoice: Invoice) => void;
};

export default function InvoiceListItem(props: InvoiceListItemProps) {
  const {
    formattedCreatedOrIssuedAt,
    legacyCreatedAt,
    finalizedAt,
    formattedFinalizedAt,
  } = invoiceTransitionTimes(props.invoice);

  return (
    <div
      key={props.invoice.id}
      onClick={(event) => {
        if (event.target instanceof HTMLAnchorElement) return;

        props.handleInvoiceClick(props.invoice);
      }}
      className="group px-4 py-3 border-b border-hero-blue-200 last:border-b-0
                cursor-pointer hover:bg-hero-blue-700/5 last:rounded-b-lg"
    >
      <div className="flex justify-between text-hero-blue-700 leading-6 font-bold mb-1">
        <div className="flex flex-row items-center gap-1">
          <div className="border-b border-dashed border-hero-blue-700 group-hover:border-solid">
            {props.invoice.stripe_invoice_number}
          </div>

          <div className="h-4 w-4 [&_path]:fill-hero-blue-700 hidden group-hover:block">
            <IconArrowNarrowRight />
          </div>
        </div>

        <div>{formatPence(props.invoice.stripe_total_excluding_tax)}</div>
      </div>

      <div className="flex justify-between items-center">
        <div>
          <div className="text-hero-blue-400 text-sm leading-5 mb-1">
            {formattedCreatedOrIssuedAt}
          </div>

          <StatusBadge
            resource="invoice"
            status={props.invoice.stripe_status}
          />
        </div>

        {props.action}
      </div>
    </div>
  );
}
