import React from "react";
import { twMerge } from "tailwind-merge";
import ld from "lodash";

type ResourceType =
  | "invoice"
  | "legacy-invoice"
  | "subscription"
  | "reader"
  | "membership_policy";

type StatusBadgeProps = {
  resource: ResourceType;
  status?: string;
  className?: string;
};

export default function StatusBadge(props: StatusBadgeProps) {
  const status = props.status || "unknown";

  const colors: Record<ResourceType, Record<string, string>> = {
    invoice: {
      unknown: "bg-hero-blue-100 text-hero-blue-800",
      draft: "bg-hero-blue-100 text-hero-blue-800",
      open: "bg-yellow-100 text-yellow-800",
      void: "bg-red-100 text-red-800",
      paid: "bg-green-100 text-green-800",
      uncollectible: "bg-red-100 text-red-800",
      partially_refunded: "bg-yellow-100 text-yellow-800",
      refunded: "bg-hero-blue-100 text-hero-blue-800",
    },
    "legacy-invoice": {
      unpaid: "bg-yellow-100 text-yellow-800",
      credit_noted: "bg-yellow-100 text-yellow-800",
      paid: "bg-green-100 text-green-800",
      rejected: "bg-red-100 text-red-800",
      cancelled_with_refund: "bg-hero-blue-100 text-hero-blue-800",
      cancelled_without_refund: "bg-hero-blue-100 text-hero-blue-800",
      no_charge: "bg-hero-blue-100 text-hero-blue-800",
      gc_pending: "bg-hero-blue-100 text-hero-blue-800",
      unbalanced: "bg-yellow-100 text-yellow-800",
      pending_paid: "bg-yellow-100 text-yellow-800",
      part_paid: "bg-yellow-100 text-yellow-800",
    },
    subscription: {
      incomplete: "bg-yellow-100 text-yellow-800",
      incomplete_expired: "bg-red-100 text-red-800",
      trialing: "bg-hero-blue-100 text-hero-blue-800",
      active: "bg-green-100 text-green-800",
      past_due: "bg-red-100 text-red-800",
      canceled: "bg-hero-blue-100 text-hero-blue-800",
      unpaid: "bg-red-100 text-red-800",
    },
    reader: {
      online: "bg-green-100 text-green-800",
      offline: "bg-red-100 text-red-800",
    },
    membership_policy: {
      cancelled: "bg-hero-blue-100 text-hero-blue-800",
      expired: "bg-red-100 text-red-800",
      active: "bg-green-100 text-green-800",
      not_started: "bg-yellow-100 text-yellow-800",
    },
  };

  return (
    <div
      className={twMerge(
        colors[props.resource][props.status],
        "rounded px-3 py-1 text-xs whitespace-nowrap font-semibold w-[min-content] mb-1",
        props.className
      )}
    >
      {ld.capitalize(status.replace("_", " "))}
    </div>
  );
}
