import { Button, ErrorSummary, Spinner, Table } from "@herohealthsoftware/ui";
import React, { useEffect, useState } from "react";
import { formatISODateTime } from "../../../../lib/datetime";
import Hero from "../../../../lib/hero";
import { translate } from "../../../../lib/i18n";
import { MembershipScheme, StripeSettings } from "../../../../lib/types";
import * as Routes from "../../../../routes";
import MembershipSchemeSidebar from "./MembershipSchemeSidebar";
import { formatPence, toPence } from "../../../../lib/money";

type MembershipSchemesSettingsProps = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  settings: StripeSettings;
};

export default function MembershipSchemesSettings(
  props: MembershipSchemesSettingsProps
) {
  const [errors, setErrors] = useState<Array<{ message: string }>>([]);
  const [membershipSchemes, setMembershipSchemes] = useState<
    MembershipScheme[]
  >([]);
  const [selectedMembershipScheme, setSelectedMembershipScheme] =
    useState<Partial<MembershipScheme> | null>(null);

  const fetchMembershipSchemes = async () => {
    try {
      const response = await Hero.fetch(
        Routes.partners_stripe_admins_membership_schemes_path()
      );

      if (response.ok) {
        let data = await response.json();
        data = data.map((membershipScheme) => {
          const price =
            typeof membershipScheme.price === "number"
              ? membershipScheme.price
              : toPence(membershipScheme.price);

          return {
            ...membershipScheme,
            price,
          };
        });

        setMembershipSchemes(data);
      } else {
        console.error(response);
        setErrors([{ message: translate("base.unexpectedError") }]);
      }
    } catch (error) {
      console.error(error);
      setErrors([{ message: translate("base.unexpectedError") }]);
    } finally {
      props.setLoading(false);
    }
  };

  const membershipSchemeRowMapper = (membershipScheme: MembershipScheme) => {
    const priceWithInterval = `${formatPence(
      membershipScheme.price
    )} / ${membershipScheme.length.replace("ly", "")}`;

    return {
      values: {
        membership: membershipScheme.name,
        product: membershipScheme.stripe_product?.name || <>&mdash;</>,
        policies: membershipScheme.active_policies_count,
        price: priceWithInterval,
        created: formatISODateTime(
          membershipScheme.created_at,
          "short-month-name-date"
        ),
      },
      onClick: () => setSelectedMembershipScheme(membershipScheme),
      className: "cursor-pointer",
    };
  };

  const blankMembershipScheme: Partial<MembershipScheme> = {
    name: "",
    stripe_product: null,
    length: "monthly",
    active_policies_count: 0,
  };

  useEffect(() => {
    fetchMembershipSchemes();
  }, []);

  return (
    <div className="flex flex-col gap-4 w-full items-start">
      {errors.length > 0 && <ErrorSummary items={errors} />}

      <Button
        variant="primary"
        onClick={() => setSelectedMembershipScheme(blankMembershipScheme)}
      >
        {translate("partners.stripe.createMembershipScheme")}
      </Button>

      {selectedMembershipScheme && (
        <MembershipSchemeSidebar
          membershipScheme={selectedMembershipScheme}
          onClose={(reload) => {
            if (reload) {
              props.setLoading(true);
              fetchMembershipSchemes();
            }

            setSelectedMembershipScheme(null);
          }}
          settings={props.settings}
        />
      )}

      {props.loading && (
        <div
          className="w-full h-64 flex flex-col items-center justify-center
                border border-hero-blue-200 rounded p-6"
        >
          <div className="h-8 w-8">
            <Spinner />
          </div>

          <div className="mt-2 font-medium">
            {translate(
              "partners.stripe.tables.membershipSchemes.loadingMessage"
            )}
            <>&hellip;</>
          </div>
        </div>
      )}

      {!props.loading && membershipSchemes.length === 0 && (
        <div
          className="w-full h-64 flex flex-col items-center justify-center
                border border-hero-blue-200 rounded p-6"
        >
          <div className="mt-2 font-medium text-hero-blue-400">
            {translate(
              "partners.stripe.tables.membershipSchemes.noResultsMessage"
            )}
          </div>
        </div>
      )}

      {!props.loading && membershipSchemes.length > 0 && (
        <div className="w-full">
          <Table
            headers={translate(
              "partners.stripe.tables.membershipSchemes.headers"
            )}
            rows={membershipSchemes.map(membershipSchemeRowMapper)}
            className="w-full"
          />
        </div>
      )}
    </div>
  );
}
