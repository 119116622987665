import {
  Button,
  Datepicker,
  ErrorSummary,
  Select,
  TextInput,
  ThemeProvider,
} from "@herohealthsoftware/ui";
import React, { useEffect, useState } from "react";
import { fetchCustomer, getDefaultSource } from "../../../../lib/customer";
import Hero from "../../../../lib/hero";
import { translate } from "../../../../lib/i18n";
import { formatPence } from "../../../../lib/money";
import {
  CardReader,
  CardReaderLocation,
  Customer,
  Invoice,
  PatientRecord,
} from "../../../../lib/types";
import * as Routes from "../../../../routes";
import Radio from "../../../atoms/Radio";
import PageHeader from "../../../shared/PageHeader";
import PatientEntityCard from "../../../shared/PatientEntityCard";
import CustomerEntityCard from "../CustomerEntityCard";
import StatusBadge from "../StatusBadge";
import CardReaderSelect from "./CardReaderSelect";
import PaymentSourceSelect from "./PaymentSourceSelect";

type PaymentsNewProps = {
  invoice: Invoice;
  patient_record: PatientRecord;
  customer: Customer;
  payment_source: string;
  stripe_base_url: string;
  stripe_publishable_key: string;
  stripe_account: string;
  back_path: string;
  card_readers: [CardReaderLocation, CardReader[]][];
};

export default function PaymentsNew(props: PaymentsNewProps) {
  const [customer, setCustomer] = useState(props.customer);
  const [loading, setLoading] = useState(false);
  const [cardReaderCancelled, setCardReaderCancelled] = useState(false);
  const [waitingForCardReader, setWaitingForCardReader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState({
    payment_method_type: null,
    stripe_payment_source:
      props.payment_source || getDefaultSource(props.customer),
    external_payment_source: null,
    external_payment_date: new Date(),
    external_payment_reference: "",
    terminal: null,
    simulated_card_number: null,
  });

  const validate = (form) => {
    if (form.payment_method_type === "stripe") {
      return !!form.stripe_payment_source;
    } else if (form.payment_method_type === "terminal") {
      return !!form.terminal;
    } else if (form.payment_method_type === "external") {
      return form.external_payment_source && form.external_payment_date;
    }
  };

  const handleInputChange = (name: string) => {
    return (value) => {
      setForm({ ...form, [name]: value });
    };
  };

  const handlePaymentMethodTypeChange = (value: string) => {
    return () => {
      setForm({ ...form, payment_method_type: value });
    };
  };

  const submitPayment = async (payment) => {
    return Hero.fetch(
      Routes.partners_stripe_admins_invoice_payments_path(props.invoice.id, {
        back_path: props.back_path,
      }),
      {
        method: "POST",
        body: { payment },
      }
    );
  };

  let polling;
  const handleWaitingForCardReader = async (payment) => {
    polling = setInterval(async () => {
      // IMPORTANT: Do not pass terminal_initiate=true here
      const response = await submitPayment({
        ...payment,
        terminal_initiate: false,
      });
      if (response.status === 202) return;

      clearInterval(polling);
      const data = await response.json();

      if (response.status === 200 || response.status === 201) {
        window.location.href = data.redirect;
      } else {
        const messages = [data.error].flat().map((message) => ({ message }));
        setErrors(messages);
      }

      setWaitingForCardReader(false);
      setLoading(false);
    }, 2500);
  };

  const cancelCardReader = async () => {
    const response = await submitPayment({
      ...form,
      terminal_cancelled: true,
      terminal_initiate: false,
    });

    if (response.ok) {
      clearInterval(polling);
      setWaitingForCardReader(false);
      setCardReaderCancelled(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (cardReaderCancelled) {
      cancelCardReader();
    }
  }, [cardReaderCancelled]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (waitingForCardReader) return;

    setLoading(true);
    setErrors([]);
    if (form.payment_method_type === "terminal") setWaitingForCardReader(true);

    try {
      const response = await submitPayment({
        ...form,
        terminal_initiate: true,
      });
      const data = await response.json();

      if (response.status === 200 || response.status === 201) {
        window.location.href = data.redirect;
      } else if (response.status === 202) {
        return handleWaitingForCardReader({ ...form });
      } else {
        const messages = [data.error].flat().map((message) => ({ message }));
        setErrors(messages);
      }
    } catch (error) {
      setErrors([{ message: translate("base.unexpectedError") }]);
    }

    setWaitingForCardReader(false);
    setLoading(false);
  };

  const onPaymentSourceSelectReload = async () => {
    const data = await fetchCustomer(customer);
    setCustomer(data);
    handleInputChange("stripe_payment_source")(getDefaultSource(data));
  };

  const externalPaymentSourceOptions = [
    "cash",
    "cheque",
    "bank_transfer",
    "insurance",
    "other",
  ].map((value) => ({
    label: translate(`partners.stripe.externalPaymentSource.${value}`),
    value,
  }));

  const submitButtons = (
    <div className="flex justify-end items-center gap-2">
      {props.invoice.stripe_status === "draft" && (
        <Button variant="white">
          <a
            href={Routes.edit_partners_stripe_admins_invoice_path(
              props.invoice.id,
              { back_path: props.back_path }
            )}
          >
            {translate("partners.stripe.editInvoice")}
          </a>
        </Button>
      )}

      <Button
        type="submit"
        variant="primary"
        disabled={!validate(form)}
        loading={loading}
        form="payment-form"
      >
        {translate("partners.stripe.takePayment")}
      </Button>
    </div>
  );

  return (
    <ThemeProvider>
      <PageHeader
        heading={
          <div className="flex flex-row items-center justify-start gap-2">
            <div>
              {translate("partners.stripe.payInvoice")}

              {props.invoice.stripe_status !== "draft" &&
                ` (${props.invoice.stripe_invoice_number})`}
            </div>

            <StatusBadge
              resource="invoice"
              status={props.invoice.stripe_status}
              className="mb-0"
            />
          </div>
        }
        subheading={`${translate("partners.stripe.amountDue")} ${formatPence(
          props.invoice.stripe_amount_due ||
            props.invoice.stripe_total_excluding_tax
        )}`}
        fullWidth
        backCross
        backPath={props.back_path}
      >
        {submitButtons}
      </PageHeader>

      <div className="px-10 py-6 my-6 gap-4 max-w-3xl center-block">
        <div className="flex flex-col gap-5 mb-4">
          {errors.length > 0 && (
            <ErrorSummary
              title={translate("partners.stripe.paymentError")}
              items={errors}
            />
          )}

          <PatientEntityCard
            label={translate("base.patient")}
            patient_record={props.patient_record}
          />

          <CustomerEntityCard customer={customer} />

          <form id="payment-form" onSubmit={handleSubmit}>
            <div>
              <div className="text-xl leading-7 font-bold text-hero-blue-700 my-5">
                {translate("partners.stripe.paymentMethod")}
                <div className="text-sm leading-5 font-normal text-hero-blue-400"></div>
              </div>

              <div className="flex flex-col gap-4 mb-5">
                <Radio
                  name="stripe_payment_method_type"
                  value="terminal"
                  label={translate("partners.stripe.cardReaderPayment")}
                  subtext={translate("partners.stripe.cardReaderPaymentHelp")}
                  checked={form.payment_method_type === "terminal"}
                  onChange={handlePaymentMethodTypeChange("terminal")}
                />

                {form.payment_method_type === "terminal" && (
                  <div className="ml-6">
                    <CardReaderSelect
                      card_readers={props.card_readers}
                      waiting={waitingForCardReader}
                      cancelled={cardReaderCancelled}
                      setCancelled={setCardReaderCancelled}
                      onReaderChange={(reader: CardReader) => {
                        handleInputChange("terminal")(reader.id);

                        if (reader.simulated_card_number) {
                          handleInputChange("simulated_card_number")(
                            reader.simulated_card_number
                          );
                        }
                      }}
                      stripeBaseUrl={props.stripe_base_url}
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-4 mb-5">
                <Radio
                  name="stripe_payment_method_type"
                  value="stripe"
                  label={translate("partners.stripe.chargeCustomer")}
                  subtext={translate("partners.stripe.chargeCustomerHelp")}
                  checked={form.payment_method_type === "stripe"}
                  onChange={handlePaymentMethodTypeChange("stripe")}
                />

                {form.payment_method_type === "stripe" && (
                  <div className="ml-6">
                    <PaymentSourceSelect
                      customer={customer}
                      payment_source={form.stripe_payment_source}
                      payment_source_field_name="stripe_payment_source"
                      stripeBaseUrl={props.stripe_base_url}
                      onChange={handleInputChange("stripe_payment_source")}
                      onReloadClick={onPaymentSourceSelectReload}
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-4 mb-5">
                <Radio
                  name="stripe_payment_method_type"
                  value="external"
                  label={translate("partners.stripe.externalPayment")}
                  subtext={translate("partners.stripe.externalPaymentHelp")}
                  checked={form.payment_method_type === "external"}
                  onChange={handlePaymentMethodTypeChange("external")}
                />

                {form.payment_method_type === "external" && (
                  <div className="flex-col ml-6">
                    <div className="flex gap-4">
                      <div className="form-group w-80">
                        <label className="control-label">
                          {translate("partners.stripe.paymentMethod")}
                        </label>

                        <Select
                          name="external_payment_source"
                          value={form.external_payment_source}
                          placeholder={translate(
                            "partners.stripe.selectAPaymentMethod"
                          )}
                          options={externalPaymentSourceOptions}
                          onChange={handleInputChange(
                            "external_payment_source"
                          )}
                        />
                      </div>

                      <div className="form-group">
                        <label className="control-label">
                          {translate("partners.stripe.paymentDate")}
                        </label>

                        <Datepicker
                          name="external_payment_date"
                          placeholderText={translate("base.selectADate")}
                          onChange={handleInputChange("external_payment_date")}
                          selected={form.external_payment_date}
                          autoComplete="off"
                          maxDate={new Date()}
                        />
                      </div>
                    </div>

                    <div className="flex">
                      <div className="form-group w-full">
                        <label className="control-label">
                          {translate("partners.stripe.referenceNote")}
                        </label>

                        <TextInput
                          name="external_payment_reference"
                          value={form.external_payment_reference}
                          helpText={translate(
                            "partners.stripe.referenceNoteHelp"
                          )}
                          onChange={(event) =>
                            handleInputChange("external_payment_reference")(
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-6">{submitButtons}</div>
          </form>
        </div>
      </div>
    </ThemeProvider>
  );
}
