import React from "react";
import HeroLinkBuilder from "@herohealthsoftware/link-builder-react";

const LinkBuilder = ({ env, kinde_jwt_id_token, tenantId }) => {
  return (
    <div>
      <HeroLinkBuilder
        env={env === "production" ? "production" : "staging"}
        jwtToken={kinde_jwt_id_token}
        tenantId={tenantId}
      />
    </div>
  );
};

export default LinkBuilder;
