import React, { PropsWithChildren } from "react";

type ToggleProps = PropsWithChildren<{
  checked?: boolean;
  onChange?: () => void;
  label?: string;
  className?: string;
  disabled?: boolean;
}>;

export default function Toggle(props: ToggleProps) {
  return (
    <label className={`flex gap-2 w-min ${props.className || ""}`}>
      <input
        className="hidden"
        type="checkbox"
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <div
        className={`w-[38px] h-5 p-0.5 flex items-center rounded-xl ${
          props.checked && !props.disabled
            ? "bg-green-500"
            : props.disabled
            ? "bg-green-100"
            : "bg-hero-blue-200"
        }`}
      >
        <div
          className={`h-4 w-4 bg-white rounded-full drop-shadow-toggle ${
            props.checked ? "ml-auto" : ""
          }`}
        ></div>
      </div>
      <div className="flex flex-col text-sm leading-5 font-semibold text-hero-blue-700 whitespace-nowrap">
        {props.children || props.label}
      </div>
    </label>
  );
}
