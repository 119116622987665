import React from "react";

import { IconPlus1, IconRefresh } from "@herohealthsoftware/ui";
import { translate } from "../../../../lib/i18n";
import { Customer } from "../../../../lib/types";

export type CustomerSearchResultsProps = {
  results: any[];
  searchValue: string;
  handleResultClick: (result: any) => void;
  handleRefreshButton: () => void;
  resetCustomerSearch: (customer: any) => void;
  setCreateCustomerOpen: (open: boolean) => void;
  customer?: Customer;
  stripe_base_url: string;
};

export default function CustomerSearchResults(
  props: CustomerSearchResultsProps
) {
  const {
    results,
    searchValue,
    handleResultClick,
    handleRefreshButton,
    resetCustomerSearch,
    customer,
    stripe_base_url,
    setCreateCustomerOpen,
  } = props;

  const customers = results.map((result) => (
    <div
      key={result.stripe_id}
      onClick={() => handleResultClick(result)}
      className="px-3 py-2 hover:bg-hero-blue-50 cursor-pointer"
    >
      <div className="flex justify-between text-sm leading-5 font-medium text-hero-blue-700">
        <div>{result.name}</div>
      </div>

      <div className="mt-0.5 flex gap-2 text-xs leading-4 font-semibold text-hero-blue-500">
        <span>
          {translate("base.phone")}
          <span className="ml-1 font-normal">
            {result.phone || <>&mdash;</>}
          </span>
        </span>
        <span>
          {translate("base.email")}
          <span className="ml-1 font-normal">
            {result.email || <>&mdash;</>}
          </span>
        </span>
      </div>
    </div>
  ));

  const refreshButton =
    searchValue.length > 0 ? (
      <div
        key="refresh-customers-button"
        onClick={() => handleRefreshButton()}
        className={`px-3 py-2 hover:bg-hero-blue-50 cursor-pointer ${
          (results.length > 0 || searchValue.length > 0) &&
          "border-t border-t-border-secondary-base"
        }`}
      >
        <div className="flex justify-between text-sm leading-5 font-normal text-hero-blue-500">
          {translate("partners.stripe.refreshCustomers")}
        </div>

        <div className="mt-0.5 flex gap-1 text-sm leading-4 font-bold text-hero-primary-700 underline py-auto">
          {translate("base.refresh")}
          <span className="w-4 h-4 font-bold [&_path]:fill-hero-primary-700">
            <IconRefresh />
          </span>
        </div>
      </div>
    ) : null;

  const addCustomerButton = (
    <div
      key="create-new-customer"
      className={`px-3 py-2 hover:bg-hero-blue-50 cursor-pointer ${
        results.length > 0 && "border-b border-b-border-secondary-base"
      }`}
    >
      <a
        href="#"
        className="flex items-center gap-2 font-medium"
        onClick={(ev) => {
          ev.preventDefault();

          resetCustomerSearch(customer);
          setCreateCustomerOpen(true);
        }}
      >
        <div className="h-4 w-4">
          <IconPlus1 />
        </div>
        <span>{translate("partners.stripe.createNewCustomer")}</span>
      </a>
    </div>
  );

  return [addCustomerButton, ...customers, refreshButton];
}
