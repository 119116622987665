import {
  Button,
  ErrorSummary,
  IconDownload,
  IconExternalLink,
  IconPencil,
} from "@herohealthsoftware/ui";
import React, { Fragment, useEffect, useState } from "react";
import { formatISODateTime } from "../../../lib/datetime";
import Hero from "../../../lib/hero";
import { translate } from "../../../lib/i18n";
import { invoiceTransitionTimes } from "../../../lib/invoice";
import { formatCardSource, formatPence } from "../../../lib/money";
import { Invoice } from "../../../lib/types";
import * as Routes from "../../../routes";
import StatusBadge from "./StatusBadge";

export type InvoiceProps = {
  invoice: Invoice;
  stripeBaseUrl: string;
  handleBackClick: () => void;
};

export default function ShowInvoice(props: InvoiceProps) {
  const [showCopiedTag, setShowCopiedTag] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<{ message: string }>>([]);
  const [insuranceInvoiceURL, setInsuranceInvoiceURL] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("summary");
  const patientInformationMetadata = {
    Name: "Patient name",
    Born: "Patient DOB",
    Sex: "Patient sex",
    Mobile: "Patient mobile",
    "Patient ID": "Hero patient ID",
    Postcode: "Patient postcode",
  };
  const invoiceInformationMetadata = [
    "Issued by",
    "Hero invoice ID",
    "Hero record ID",
    "Last updated in Hero",
  ];
  const activeClass = "bg-hero-blue-100 text-hero-blue-700";
  const inActiveClass = "bg-hero-blue-50 text-hero-blue-400";

  const handleCopyPaymentClick = () => {
    navigator.clipboard.writeText(props.invoice.stripe_hosted_invoice_url);
    setShowCopiedTag(true);
    setTimeout(() => setShowCopiedTag(false), 3000);
  };

  const handleDeleteClick = async () => {
    try {
      setErrors([]);

      const response = await Hero.fetch(
        Routes.partners_stripe_admins_invoice_path(props.invoice.id),
        {
          method: "DELETE",
          body: {},
        }
      );

      const data = await response.json();

      if (response.ok) {
        window.location.href = data.redirect;
      } else {
        setErrors([{ message: data.error }]);
      }
    } catch (error) {
      setErrors([
        { message: error.message || translate("base.unexpectedError") },
      ]);
    }
  };

  const showOnStripeStatuses = (stripeStatuses) => {
    return stripeStatuses.includes(props.invoice.stripe_status);
  };

  let billedMethodText;
  if (props.invoice.external_payment_source) {
    billedMethodText = translate(
      `partners.stripe.externalPaymentSource.${props.invoice.external_payment_source}`
    );
  } else if (props.invoice.collection_method === "charge_customer") {
    billedMethodText = translate("partners.stripe.chargeCustomer");
  } else if (props.invoice.collection_method === "request_payment") {
    billedMethodText = translate("partners.stripe.requestPayment");
  }

  const {
    formattedCreatedOrIssuedAt,
    legacyCreatedAt,
    finalizedAt,
    formattedFinalizedAt,
  } = invoiceTransitionTimes(props.invoice);

  const fetchInsuranceInvoiceURL = async () => {
    try {
      const response = await fetch(
        Routes.generate_insurance_invoice_url_partners_stripe_admins_invoice_path(
          props.invoice.id
        )
      );
      const data = await response.json();
      setInsuranceInvoiceURL(data.url);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInsuranceInvoiceURL();
  }, []);

  return (
    <div className="p-4 w-[600px] h-[calc(100vh-100px)] overflow-y-scroll">
      <div
        className="font-bold text-xs cursor-pointer mb-1"
        onClick={props.handleBackClick}
      >
        ←{" "}
        <span className="border-b border-dashed border-hero-blue-700">
          {translate("base.back")}
        </span>
      </div>

      <ErrorSummary items={errors} className="mt-4 mb-2" />

      <div className="flex justify-between items-center">
        <div className="text-xl leading-7 font-bold">
          {props.invoice.stripe_invoice_number} {translate("base.for") + " "}
          {formatPence(props.invoice.stripe_total_excluding_tax)}
        </div>
      </div>

      <div className="flex text-hero-blue-700 text-sm mb-1">
        <div className="font-semibold mr-1">
          {legacyCreatedAt
            ? translate("partners.stripe.legacyCreated")
            : finalizedAt
            ? translate("base.issued")
            : translate("base.created")}
        </div>

        <div>{formattedCreatedOrIssuedAt}</div>

        {legacyCreatedAt && finalizedAt && (
          <>
            <div className="font-semibold mr-1 ml-2">
              {translate("base.issued")}
            </div>

            <div>{formattedFinalizedAt}</div>
          </>
        )}
      </div>
      {props.invoice.stripe_payment_card ? (
        <>
          <div className="flex text-hero-blue-700 text-sm mb-1">
            <div className="font-semibold mr-1">
              {translate("partners.stripe.payment")}
            </div>

            <div>{formatCardSource(props.invoice.stripe_payment_card)}</div>
          </div>
        </>
      ) : (
        props.invoice.external_payment_source && (
          <>
            <div className="flex text-hero-blue-700 text-sm mb-1">
              <div className="font-semibold mr-1">
                {translate("partners.stripe.payment")}
              </div>

              <div>
                {translate("partners.stripe.external")}&nbsp;&ndash;&nbsp;
                {billedMethodText}&nbsp;
                {props.invoice.external_payment_date &&
                  formatISODateTime(
                    props.invoice.external_payment_date,
                    "date"
                  )}
              </div>
            </div>
          </>
        )
      )}

      <StatusBadge resource="invoice" status={props.invoice.stripe_status} />

      <div className="flex gap-4">
        <div>
          <a
            href={`${props.stripeBaseUrl}/invoices/${props.invoice.stripe_id}`}
            className="text-hero-primary-700 font-bold flex items-center gap-1 mb-4"
            target="_blank"
          >
            <span className="border-b border-hero-primary-700">
              {translate("partners.stripe.goToInvoiceOnStripe")}
            </span>
            <div className="h-4 w-4 [&_path]:fill-hero-primary-700">
              <IconExternalLink />
            </div>
          </a>
        </div>
        <div>
          <a
            href={insuranceInvoiceURL}
            className="text-hero-primary-700 font-bold flex items-center gap-1 mb-4"
            target="_blank"
          >
            <span className="border-b border-hero-primary-700">
              {translate("partners.stripe.downloadInsuranceInvoice")}
            </span>
            <div className="h-4 w-4 [&_path]:fill-hero-primary-700">
              <IconDownload />
            </div>
          </a>
        </div>
      </div>

      <div className="flex mb-4 bg-hero-blue-50 font-bold w-[min-content] rounded-md p-1">
        <button
          className={`px-3 py-1 rounded ${
            activeTab === "summary" ? activeClass : inActiveClass
          }`}
          onClick={() => setActiveTab("summary")}
        >
          {translate("base.summary")}
        </button>
        <button
          className={`px-3 py-1 ${
            activeTab === "metadata" ? activeClass : inActiveClass
          }`}
          onClick={() => setActiveTab("metadata")}
        >
          {translate("base.metaData")}
        </button>
      </div>
      <div className="mb-4">
        {activeTab === "summary" ? (
          <>
            <div className="mb-4">
              <div className="flex gap-2 mb-2">
                {translate("partners.stripe.billingDetails")}
                {props.invoice.customer.name ? (
                  <div className="flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit">
                    <a
                      href={`${props.stripeBaseUrl}/customers/${props.invoice.customer?.stripe_id}`}
                      target="_blank"
                      className="flex gap-1 items-center"
                    >
                      <span className="border-b border-dashed border-hero-blue-700">
                        {props.invoice.customer.name}
                      </span>
                      <div className="h-4 w-4 [&_path]:fill-hero-blue-700">
                        <IconExternalLink />
                      </div>
                    </a>
                  </div>
                ) : (
                  <>&mdash;</>
                )}
              </div>
              <div className="flex gap-2 mb-2">
                {translate("partners.stripe.billedTo")}
                <div className="flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit">
                  {props.invoice.customer.email || <> &mdash; </>}
                </div>
              </div>
              <div className="flex gap-2 mb-2">
                {translate("partners.stripe.billedMethod")}
                <div className="flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit">
                  {billedMethodText}
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex gap-2 items-center text-lg leading-7 font-bold mb-2">
                {translate("partners.stripe.serviceInformation")}
                {showOnStripeStatuses(["draft", "open", "paid"]) && (
                  <Button icon variant="white" size="xs">
                    <a
                      href={Routes.edit_partners_stripe_admins_invoice_path(
                        props.invoice.id
                      )}
                      className="flex gap-1"
                    >
                      <IconPencil />
                      {translate("base.edit")}
                    </a>
                  </Button>
                )}
              </div>
              {props.invoice.service_date && (
                <div className="flex gap-2 mb-2">
                  {translate("partners.stripe.serviceDate")}
                  <div className="flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit">
                    {formatISODateTime(
                      props.invoice.service_date,
                      "short-dashed-datetime"
                    )}
                  </div>
                </div>
              )}
              {props.invoice.stripe_metadata["Service practitioner"] && (
                <div className="flex gap-2 mb-2">
                  {translate("partners.stripe.practitioner")}
                  <div className="flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit">
                    {props.invoice.stripe_metadata["Service practitioner"]}
                  </div>
                </div>
              )}
              {props.invoice.stripe_metadata["Appointment name"] && (
                <div className="flex gap-2 mb-2">
                  {translate("partners.stripe.appointment")}
                  <div className="flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit">
                    {props.invoice.stripe_metadata["Appointment name"]}
                  </div>
                </div>
              )}
              {props.invoice.diagnosis && (
                <div className="flex gap-2 mb-2">
                  {translate("partners.stripe.diagnosis")}
                  <div className="flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit">
                    {props.invoice.diagnosis}
                  </div>
                </div>
              )}
            </div>
            <div className="mb-4">
              <div className="flex gap-2 items-center text-lg leading-7 font-bold mb-2">
                {translate("partners.stripe.items")}
                {props.invoice.stripe_status === "draft" && (
                  <Button icon variant="white" size="xs">
                    <a
                      href={Routes.edit_partners_stripe_admins_invoice_path(
                        props.invoice.id
                      )}
                      className="flex gap-1"
                    >
                      <IconPencil />
                      {translate("base.edit")}
                    </a>
                  </Button>
                )}
              </div>
              <div className="border border-hero-blue-200 rounded-lg mb-8">
                {(props.invoice.invoice_items || []).length < 1 ? (
                  <div
                    className="flex items-center justify-center font-semibold
                    text-hero-blue-700 py-6"
                  >
                    {translate("partners.stripe.thisInvoiceHasNoItems")}
                  </div>
                ) : (
                  props.invoice.invoice_items.map((item) => {
                    const discountAmount = (item.discounts || [])
                      .map((discount) => discount.amount)
                      .reduce((sum, num) => sum + num, 0);

                    return (
                      <div
                        key={item.stripe_id}
                        className="grid grid-cols-5 gap-2 px-3 py-2 font-bold
                            border-b border-hero-blue-200"
                      >
                        <div className="col-span-2">{item.description}</div>
                        <div className="">{item.quantity}</div>
                        <div className="text-right">
                          {formatPence(item.unit_amount)}
                        </div>
                        <div className="text-right">
                          {formatPence(item.unit_amount * item.quantity)}
                        </div>

                        <div className="col-span-2 flex gap-1 text-xs text-hero-blue-400">
                          <div>{translate("partners.stripe.code")}</div>
                          <div className="font-normal mr-1">
                            {item.product?.code || <>&ndash;</>}
                          </div>

                          <div>{translate("base.category")}</div>
                          <div className="font-normal mr-1">
                            {item.product?.category || <>&ndash;</>}
                          </div>
                        </div>
                        <div className="flex justify-end col-span-2">
                          {(item.discounts || []).map((discount, index) => (
                            <div
                              key={index}
                              className="bg-hero-blue-100 px-2.5 py-0.5 text-xs
                                  rounded mr-1"
                            >
                              {discount.description}
                            </div>
                          ))}
                        </div>
                        <div className="font-normal text-right">
                          {discountAmount > 0 && (
                            <>&minus;{formatPence(discountAmount)}</>
                          )}
                        </div>
                      </div>
                    );
                  })
                )}

                <div className="grid grid-cols-5 gap-2 px-3 py-2 font-semibold text-right">
                  <div className="col-span-4">
                    {translate("partners.stripe.subtotal")}
                  </div>
                  <div className="">
                    {formatPence(props.invoice.stripe_subtotal)}
                  </div>

                  {(props.invoice.stripe_total_discount_amounts || []).map(
                    (discount_amount, index) => (
                      <Fragment key={index}>
                        <div className="col-span-4 text-hero-blue-400">
                          {discount_amount.description}
                        </div>
                        <div className="text-hero-blue-400">
                          &minus;{formatPence(discount_amount.amount)}
                        </div>
                      </Fragment>
                    )
                  )}

                  <div className="col-span-4">
                    {translate("partners.stripe.total")}
                  </div>
                  <div className="">
                    {formatPence(props.invoice.stripe_total_excluding_tax)}
                  </div>

                  <div className="col-span-4">
                    {translate("partners.stripe.amountDue")}
                  </div>
                  <div className="">
                    {formatPence(props.invoice.stripe_amount_remaining)}
                  </div>

                  <div className="col-span-5">
                    {props.invoice.stripe_hosted_invoice_url &&
                      props.invoice.stripe_status === "open" && (
                        <a
                          className={`relative b b-outline b-xs `}
                          onClick={handleCopyPaymentClick}
                        >
                          {showCopiedTag && (
                            <div
                              className="absolute -top-6 px-1 py-0.5 rounded bg-hero-blue-900
                          text-white text-xs shadow-sm"
                            >
                              {translate("base.copied")}
                            </div>
                          )}
                          {translate("partners.stripe.copyPaymentLink")}
                        </a>
                      )}

                    {showOnStripeStatuses(["draft"]) && (
                      <button
                        className={`b b-xs text-red-700`}
                        onClick={handleDeleteClick}
                      >
                        {translate("base.delete_invoice")}
                      </button>
                    )}

                    {showOnStripeStatuses(["draft", "open"]) && (
                      <a
                        href={Routes.new_partners_stripe_admins_invoice_payment_path(
                          props.invoice.id,
                          { back_path: window.location.pathname }
                        )}
                        className={`b b-primary b-xs`}
                      >
                        {translate("partners.stripe.payNow")}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex gap-2 items-center text-lg leading-7 font-bold mb-2">
                {translate("partners.stripe.billingInformation")}
                {showOnStripeStatuses(["draft", "open", "paid"]) && (
                  <Button icon variant="white" size="xs">
                    <a
                      href={Routes.edit_partners_stripe_admins_invoice_path(
                        props.invoice.id
                      )}
                      className="flex gap-1"
                    >
                      <IconPencil />
                      {translate("base.edit")}
                    </a>
                  </Button>
                )}
              </div>
              {props.invoice.insurer && (
                <div className="flex gap-2 mb-2">
                  {translate("partners.stripe.insurer")}
                  <div className="flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit">
                    {props.invoice.insurer}
                  </div>
                </div>
              )}
              {props.invoice.authentication_code && (
                <div className="flex gap-2 mb-2">
                  {translate("partners.stripe.authorisationCode")}
                  <div className="flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit">
                    {props.invoice.authentication_code}
                  </div>
                </div>
              )}
              {props.invoice.membership_number && (
                <div className="flex gap-2 mb-2">
                  {translate("partners.stripe.membershipNumber")}
                  <div className="flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit">
                    {props.invoice.membership_number}
                  </div>
                </div>
              )}
              {props.invoice.stripe_metadata[
                "Patient registered practitioner"
              ] && (
                <div className="flex gap-2 mb-2">
                  {translate("partners.stripe.registeredPractitioner")}
                  <div className="flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit">
                    {
                      props.invoice.stripe_metadata[
                        "Patient registered practitioner"
                      ]
                    }
                  </div>
                </div>
              )}
              <div className="flex gap-2 mb-2">
                {translate("partners.stripe.billingAndCollection")}
                <div className="flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit">
                  {props.invoice.billing_and_collection
                    ? translate("base.yes")
                    : translate("base.no")}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="text-lg leading-7 font-bold mb-3">
              {translate("partners.stripe.metadata")}
            </div>
            <div>
              <div className="text-base leading-7 font-bold mb-2">
                {translate("partners.stripe.patientInformation")}
              </div>
              {Object.entries(patientInformationMetadata).map(
                ([label, key]) => (
                  <div key={label}>
                    {props.invoice.stripe_metadata[key] && (
                      <div className="flex gap-2 mb-2">
                        {label}
                        <div
                          className={`flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit
                          ${
                            label === "Name" &&
                            "border-b border-dashed border-hero-blue-700"
                          }`}
                        >
                          {props.invoice.stripe_metadata[key]}
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
            <div>
              <div className="text-base leading-7 font-bold mb-2">
                {translate("partners.stripe.invoiceInformation")}
              </div>
              {invoiceInformationMetadata.map((label) => (
                <div key={label}>
                  {props.invoice.stripe_metadata[label] && (
                    <div className="flex gap-2 mb-2">
                      {label}
                      <div className="flex gap-1 items-center text-hero-blue-700 font-semibold justify-start w-fit">
                        {label === "Last updated in Hero"
                          ? props.invoice.stripe_metadata[label] &&
                            formatISODateTime(
                              props.invoice.stripe_metadata[label],
                              "short-dashed-datetime"
                            )
                          : props.invoice.stripe_metadata[label]}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
