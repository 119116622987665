import { IconPlus1 } from "@herohealthsoftware/ui";
import React, { useState } from "react";
import { translate } from "../../../../lib/i18n";
import {
  EmisItem,
  InvoiceItem,
  PatientRecord,
  Product,
} from "../../../../lib/types";
import * as Routes from "../../../../routes";
import { ProductSearch } from "../ProductSearch";
import { EmisItemsModal } from "./EmisItemsModal";
import { ItemsInputConfig } from "./ItemsInput";

type ItemsSearchProps = {
  onNewItemClick: (item: Partial<InvoiceItem>) => void;
  onSelectProductClick: (item: Product) => void;
  onSubmitEmisItems?: (emisItems: EmisItem[]) => void;
  config: Pick<ItemsInputConfig, "oneTimeItems" | "emisItems">;
  focusRef?: React.MutableRefObject<any>;
};

export default function ItemsSearch(props: ItemsSearchProps) {
  const [showEmisProducts, setShowEmisProducts] = useState(false);
  const emisItems = props.config.emisItems || undefined;

  const actions = [
    {
      key: "add-one-off-item",
      enabled: props.config.oneTimeItems,
      label: translate("partners.stripe.addOneTimeItem"),
      icon: <IconPlus1 />,
      onClick: () => props.onNewItemClick({ create: false }),
    },
    {
      key: "create-new-product",
      enabled: true,
      label: translate("partners.stripe.createNewProduct"),
      icon: <IconPlus1 />,
      onClick: () => props.onNewItemClick({ create: true }),
    },
    {
      key: "add-emis-product",
      enabled: !!emisItems?.enabled,
      label: translate("partners.stripe.addProductFromEmis"),
      icon: <IconPlus1 />,
      onClick: () => setShowEmisProducts(true),
    },
  ];

  return (
    <>
      {emisItems?.enabled && (
        <EmisItemsModal
          open={showEmisProducts}
          onOpenChange={setShowEmisProducts}
          onSubmitEmisItems={props.onSubmitEmisItems}
          emisItemsPath={Routes.partners_stripe_admins_emis_path({
            patient_record_id: emisItems.patient_record.id,
          })}
        />
      )}

      <ProductSearch
        actions={actions}
        focusRef={props.focusRef}
        placeholder={translate("partners.stripe.findOrAddAnItem")}
        onProductClick={props.onSelectProductClick}
      />
    </>
  );
}
