import {
  Button,
  Modal,
  ModalBody,
  ModalClose,
  ModalContent,
  ModalFooter,
  ModalHeader,
  TextInput,
  Typography,
} from "@herohealthsoftware/ui";
import React, { useState } from "react";
import { translate } from "../../../../lib/i18n";
import { Customer } from "../../../../lib/types";
import { createCustomer } from "../../../../lib/customer";
import { create } from "lodash";

export type CreateCustomerModalProps = {
  resetCustomerSearch: (customer: any) => void;
  customer: Customer;
  createCustomerOpen: boolean;
  setCreateCustomerOpen: (open: boolean) => void;
  setCustomer: (customer: Customer) => void;
  setShowCustomerSearch: (show: boolean) => void;
  setShowCustomerSearchResults: (show: boolean) => void;
};

export default function CreateCustomerModal(props: CreateCustomerModalProps) {
  const {
    resetCustomerSearch,
    customer,
    createCustomerOpen,
    setCreateCustomerOpen,
    setCustomer,
    setShowCustomerSearch,
    setShowCustomerSearchResults,
  } = props;

  const [processingCreateCustomer, setProcessingCreateCustomer] =
    useState(false);
  const [createCustomerError, setCreateCustomerError] = useState({
    name: "",
    email: "",
    other: "",
  });
  const [newCustomer, setNewCustomer] = useState({ name: "", email: "" });

  const handleCreateCustomer = async (event) => {
    event.preventDefault();
    setProcessingCreateCustomer(true);

    if (!newCustomer.name) {
      setCreateCustomerError({
        ...createCustomerError,
        name: "A customer name is required",
      });
      setProcessingCreateCustomer(false);
      return;
    }

    try {
      const response = await createCustomer(
        newCustomer.email,
        newCustomer.name
      );
      if (response.error) {
        if (response.error.code === "email_invalid") {
          setCreateCustomerError({
            ...createCustomerError,
            email: "Please provide a valid email address",
          });
        } else {
          setCreateCustomerError({
            ...createCustomerError,
            other: response.error.message,
          });
        }
        setProcessingCreateCustomer(false);
        return;
      }

      setCustomer(response);
      setShowCustomerSearch(false);
      setShowCustomerSearchResults(false);
      setCreateCustomerOpen(false);
      setProcessingCreateCustomer(false);
      setCreateCustomerError({ name: "", email: "", other: "" });
      setNewCustomer({ name: "", email: "" });
    } catch (error) {
      setCreateCustomerError({ name: "", email: "", other: error.message });
      setProcessingCreateCustomer(false);
    }
  };

  return (
    <>
      <div
        onClick={() => resetCustomerSearch(customer)}
        className="z-40 bg-white fixed top-0 left-0 w-screen h-screen opacity-80"
      ></div>

      <Modal open={createCustomerOpen}>
        <ModalContent size="lg">
          <ModalHeader
            title={translate("partners.stripe.createStripeCustomer")}
          />
          <ModalBody>
            <div className="flex flex-col gap-4">
              {!!createCustomerError["other"] && (
                <Typography color="failure">
                  <p>{createCustomerError["other"]}</p>
                </Typography>
              )}

              <TextInput
                error={!!createCustomerError["name"]}
                errorText={createCustomerError["name"]}
                label={translate("base.fullName")}
                onChange={(ev) =>
                  setNewCustomer({
                    ...newCustomer,
                    name: ev.target.value,
                  })
                }
                value={newCustomer["name"] || ""}
              />
              <TextInput
                error={!!createCustomerError["email"]}
                errorText={createCustomerError["email"]}
                label={translate("base.emailAddress")}
                badge={true}
                required={false}
                onChange={(ev) =>
                  setNewCustomer({
                    ...newCustomer,
                    email: ev.target.value,
                  })
                }
                value={newCustomer["email"] || ""}
              />
            </div>
          </ModalBody>
          <ModalFooter className="flex justify-end">
            <ModalClose
              className="b b-outline"
              onClick={() => {
                setCreateCustomerOpen(false);
                setCreateCustomerError({
                  name: "",
                  email: "",
                  other: "",
                });
                setNewCustomer({ name: "", email: "" });
              }}
            >
              {translate("base.cancel")}
            </ModalClose>

            <Button
              className="b b-primary"
              onClick={handleCreateCustomer}
              loading={processingCreateCustomer}
            >
              {translate("partners.stripe.createCustomer")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
