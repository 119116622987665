import {
  IconExclamationCircle,
  IconSearch,
  Spinner,
} from "@herohealthsoftware/ui";
import React, { useState } from "react";
import useStripeSearch, {
  StripeSearchFilters,
} from "../../../hooks/useStripeSearch";
import { translate } from "../../../lib/i18n";
import { formatProductPrice } from "../../../lib/money";
import { Product } from "../../../lib/types";

export type ProductSearchAction = {
  key: string;
  enabled: boolean;
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
};

type ProductSearchProps = {
  onProductClick: (item: Product) => void;
  placeholder: string;
  actions?: ProductSearchAction[];
  filters?: StripeSearchFilters;
  focusRef?: React.MutableRefObject<any>;
};

export function ProductSearch(props: ProductSearchProps) {
  const { search, loading, results, setResults, total } =
    useStripeSearch("product");

  const [showDropdown, setShowDropdown] = useState(false);

  const resetSearch = () => {
    setShowDropdown(false);
    setResults([]);
  };

  const actions = props.actions || [];
  const filters = props.filters || {};

  const resultClassName =
    "flex items-center justify-between text-hero-blue-700 text-sm font-medium \
      cursor-pointer hover:bg-hero-blue-100 py-2 px-3";

  return (
    <>
      <div
        className="form-group relative"
        onFocus={() => setShowDropdown(true)}
        onBlur={() => setShowDropdown(false)}
      >
        <div className="relative md:w-1/2">
          <div className="w-5 h-5 z-20 absolute top-2 left-2 [&_path]:fill-hero-blue-400">
            <IconSearch />
          </div>
          <input
            ref={props.focusRef}
            type="search"
            onChange={(event) =>
              search(event.target.value, { min: 3, filters })
            }
            className="form-control !pl-8"
            placeholder={props.placeholder}
          />
        </div>

        {showDropdown && (actions.length > 0 || loading || total !== null) && (
          <div
            className="flex flex-col absolute w-full md:w-2/3 z-30 my-2 py-1
            bg-white border border-hero-blue-100 rounded-lg shadow-lg"
          >
            {actions.map(
              (action) =>
                action.enabled && (
                  <div
                    key={action.key}
                    className={resultClassName}
                    onMouseDown={() => {
                      resetSearch();
                      action.onClick();
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <div className="h-4 w-4">{action.icon}</div>
                      <span>{action.label}</span>
                    </div>
                  </div>
                )
            )}

            {(loading || total !== null) && (
              <>
                {actions.length > 0 && (
                  <hr className="border-top border-hero-blue-100" />
                )}

                <div
                  className="text-hero-blue-500 text-xs leading-4 font-medium
                    tracking-wider uppercase pt-2 pb-1 px-3"
                >
                  {translate("partners.stripe.stripeProducts")}
                </div>

                {loading ? (
                  <div
                    key="loading"
                    className="flex items-center text-sm font-medium py-1 px-3"
                  >
                    <Spinner className="h-6 w-6 fill-hero-blue-700" />
                    <div className="text-hero-blue-400 ml-2">
                      {translate("partners.stripe.loadingStripeProducts")}
                    </div>
                  </div>
                ) : total > 0 ? (
                  results.map((result) => (
                    <div
                      key={result.stripe_id}
                      className="flex items-start justify-between text-hero-blue-700
                        text-sm font-medium cursor-pointer hover:bg-hero-blue-100 py-2 px-3"
                      onMouseDown={() => {
                        resetSearch();
                        props.onProductClick(result);
                      }}
                    >
                      <div>{result.name}</div>
                      <div className="flex flex-col gap-1">
                        <span>{formatProductPrice(result)}</span>
                        {result.code && (
                          <span className="text-xs text-hero-blue-400 font-normal text-right">
                            {result.code}
                          </span>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    key="no-results"
                    className="flex items-center text-sm font-medium py-1 px-3"
                  >
                    <div className="h-6 w-6 [&_path]:fill-hero-blue-400">
                      <IconExclamationCircle />
                    </div>

                    <div className="text-hero-blue-400 ml-2">
                      {translate("partners.stripe.noMatchingProductsFound")}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}
