import React, { useState } from "react";
import {
  ThemeProvider,
  DataTable as HeroDataTable,
  SortingState,
  ColumnFiltersState,
} from "@herohealthsoftware/ui";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { columns } from "./Columns";
import { fetchData } from "./fetchData";
import * as Routes from "../../../../routes";

const DataTable = (props) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "confirmed_at", desc: true },
  ]);
  const dataQuery = useQuery({
    queryKey: ["data", pagination, columnFilters, sorting],
    queryFn: () => fetchData({ pagination, columnFilters, sorting, ...props }),
    placeholderData: keepPreviousData,
  });

  const onRowClick = (row: any) => {
    window.location.href = Routes.admin_appointment_path({
      id: row.original.id,
    });
  };

  return (
    <ThemeProvider>
      <HeroDataTable
        columns={columns}
        data={dataQuery.data?.rows || []}
        rowCount={dataQuery.data?.rowCount}
        loading={dataQuery.isFetching}
        manualPagination
        manualFiltering
        pagination={pagination}
        onPaginationChange={setPagination}
        columnFilters={columnFilters}
        onColumnFilterChange={setColumnFilters}
        sorting={sorting}
        setSorting={setSorting}
        onRowClick={onRowClick}
      />
    </ThemeProvider>
  );
};

export default DataTable;
