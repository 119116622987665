import React from "react";
import HeroCareNavigation from "@herohealthsoftware/care-nav-react";

const SubmissionPage = ({
  env,
  jwtToken,
  apiKey,
  practiceGroupId,
  patientId,
  adminId,
}) => {
  return (
    <>
      {jwtToken ? (
        <HeroCareNavigation
          env={env}
          jwtToken={jwtToken}
          tenantId={practiceGroupId}
          patientId={patientId}
          adminId={adminId}
        />
      ) : (
        <HeroCareNavigation
          env={env}
          apiKey={apiKey}
          tenantId={practiceGroupId}
          patientId={patientId}
          adminId={adminId}
        />
      )}
    </>
  );
};

export default SubmissionPage;
