import { IconExternalLink, IconPlus1 } from "@herohealthsoftware/ui";
import React from "react";

import { Badge, IconExclamation } from "@herohealthsoftware/ui";
import { translate } from "../../../lib/i18n";
import {
  Appointment,
  Customer,
  Invoice,
  MembershipPolicy,
  PatientRecord,
} from "../../../lib/types";
import * as Routes from "../../../routes";
import { Section } from "../../layouts/partials/integrations/Stripe";
import CustomerSettingsIndex from "./CustomerSettingsIndex";
import InvoicesList from "./InvoicesList";
import { PaginationButtonsProps } from "./PaginationButtons";
import MembershipPoliciesList from "./MembershipPoliciesList";

export type CustomerSidebarProps = {
  patient_record: PatientRecord;
  appointment?: Appointment;
  customer?: Customer;
  setCustomer: (customer: Customer) => void;
  patientCustomer?: Customer;
  setPatientCustomer: (customer: Customer) => void;
  invoices: Invoice[];
  membershipPolicies: MembershipPolicy[];
  pagination: PaginationButtonsProps;
  loading: boolean;
  error: string | null;
  stripeBaseUrl: string;
  handleInvoiceClick: (invoice: Invoice) => void;
  handleMembershipPolicyClick: (policy: MembershipPolicy) => void;
  activeSection: Section;
  setActiveSection: (name: Section) => void;
};

export default function CustomerSidebar(props: CustomerSidebarProps) {
  const classNameForSection = (section: Section) => {
    const base =
      "px-3 py-1.5 text-sm leading-4 font-semibold whitespace-nowrap";
    const colors =
      props.activeSection === section
        ? "text-hero-blue-900 bg-hero-blue-700/5"
        : "text-hero-blue-400 cursor-pointer";

    return `${base} ${colors}`;
  };

  let sections: Array<{ name: Section; label: string }>;
  if (props.appointment) {
    sections = [
      { name: "appointment", label: "This appointment" },
      { name: "patient", label: "All invoices" },
    ];
  } else {
    sections = [{ name: "patient", label: "Invoices" }];
    sections.push({ name: "memberships", label: "Memberships" });
  }

  sections.push({ name: "settings", label: "Settings" });

  const back_path = location.href.replace(location.origin, "");
  const new_invoice_params = props.appointment
    ? { appointment_id: props.appointment.id, back_path }
    : { patient_record_id: props.patient_record.id, back_path };
  const new_invoice_path =
    Routes.new_partners_stripe_admins_invoice_path(new_invoice_params);

  return (
    <div className="p-4 overflow-y-scroll w-[400px] h-[calc(100vh-100px)]">
      <h2 className="text-xl leading-7 font-bold">
        {props.patient_record.name_std}

        {props.patientCustomer && (
          <dl className="text-sm font-normal flex gap-x-2 text-hero-blue-500 mb-0">
            <dt>{translate("base.email")}</dt>
            <dd className="mr-1 mb-0">
              {props.patientCustomer.email || (
                <Badge size="sm" variant="warning" icon={<IconExclamation />}>
                  {translate("partners.stripe.noEmailAddress")}
                </Badge>
              )}
            </dd>

            <dt>{translate("base.phone")}</dt>
            <dd className="mr-1 mb-0">
              {props.patientCustomer.phone || <>&mdash;</>}
            </dd>
          </dl>
        )}
      </h2>

      {props.patientCustomer?.stripe_id ? (
        <a
          href={`${props.stripeBaseUrl}/customers/${props.patientCustomer.stripe_id}`}
          className="text-hero-primary-700 font-bold flex items-center gap-1 mb-6"
          target="_blank"
        >
          <span>{translate("partners.stripe.goToStripeDashboard")}</span>
          <div className="h-4 w-4 [&_path]:fill-hero-primary-700">
            <IconExternalLink />
          </div>
        </a>
      ) : (
        <div className="text-hero-primary-700/50 font-bold mb-8">
          {translate("partners.stripe.noStripeCustomer")}
        </div>
      )}

      <div className="mb-8">
        <a href={new_invoice_path} className="b b-primary w-full">
          <div className="h-4 w-4">
            <IconPlus1 />
          </div>
          {translate("partners.stripe.createInvoice")}
        </a>
      </div>

      {
        <>
          <ul className="bg-hero-blue-50 rounded p-1 flex w-[min-content] mb-4">
            {sections.map((section) => (
              <li
                key={section.name}
                className={classNameForSection(section.name)}
                onClick={() => props.setActiveSection(section.name)}
              >
                {section.label}
              </li>
            ))}
          </ul>

          {props.activeSection === "settings" && (
            <CustomerSettingsIndex
              setCustomer={props.setCustomer}
              customer={props.customer}
              patientCustomer={props.patientCustomer}
              setPatientCustomer={props.setPatientCustomer}
              patient_record={props.patient_record}
              stripeBaseUrl={props.stripeBaseUrl}
            />
          )}

          {["patient", "appointment"].includes(props.activeSection) && (
            <InvoicesList
              invoices={props.invoices}
              pagination={props.pagination}
              loading={props.loading}
              error={props.error}
              handleInvoiceClick={props.handleInvoiceClick}
              activeSection={props.activeSection}
            />
          )}

          {props.activeSection === "memberships" && (
            <MembershipPoliciesList
              membershipPolicies={props.membershipPolicies}
              loading={props.loading}
              error={props.error}
              handleMembershipPolicyClick={props.handleMembershipPolicyClick}
            />
          )}
        </>
      }
    </div>
  );
}
