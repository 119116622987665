import { debounce } from "lodash";
import { useState } from "react";

import * as Routes from "../routes";

export type StripeSearchType = "invoice" | "product" | "customer" | "coupon";

export type StripeSearchFilters = Record<string, string>;

export type StripeSearchOptions = {
  min: false | number;
  callback?: (results: any) => void;
  cursor?: string;
  filters?: StripeSearchFilters;
};

export default function useStripeSearch(type: StripeSearchType) {
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const search = debounce(
    async (query?: string, options?: StripeSearchOptions) => {
      setLoading(true);
      query ||= "";
      options ||= { min: 3 };

      if (options.min && query.length < Number(options.min)) {
        setLoading(false);
        setResults([]);
        setTotal(null);
        return;
      }

      const response = await searchStripe(
        query,
        type,
        options.cursor,
        options.filters
      );

      setLoading(false);
      setTotal(response.data.length);

      options.callback ? options.callback(response) : setResults(response.data);
    },
    200
  );

  return { search, loading, results, setResults, total };
}

export async function searchStripe(
  query?: string,
  type?: StripeSearchType,
  cursor?: string,
  filters?: StripeSearchFilters
): Promise<{ data: any[]; cursor: string }> {
  const url = Routes.partners_stripe_admins_search_path({
    type,
    query,
    cursor,
    filters,
  });

  const response = await fetch(url);
  const json = await response.json();

  return json;
}
