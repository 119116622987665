import { EntityCard, ThemeProvider } from "@herohealthsoftware/ui";
import React from "react";
import { translate } from "../../../lib/i18n";
import { Customer, PatientRecord } from "../../../lib/types";
import * as Routes from "../../../routes";

type CustomerEntityCardProps = Omit<
  React.ComponentProps<typeof EntityCard>,
  "title" | "label" | "information"
> & {
  customer: Customer;
  isRoot?: boolean;
};

export default function CustomerEntityCard({
  customer,
  isRoot = false,
  ...props
}: CustomerEntityCardProps) {
  const Provider = isRoot ? ThemeProvider : React.Fragment;
  const href = Routes.sidebar_partners_stripe_admins_customer_path(
    customer.stripe_id
  );

  return (
    <Provider>
      <EntityCard
        label="Stripe customer"
        title={customer.name}
        href={href}
        targetBlank={true}
        information={{
          [translate("base.mobile")]: customer.phone,
          [translate("base.email")]: customer.email,
        }}
        {...props}
      />
    </Provider>
  );
}
