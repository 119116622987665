import React, { useState } from "react";

import { translate } from "../../lib/i18n";
import useOutsideClick from "../../hooks/useOutsideClick";
import { isEqual } from "lodash";
import {
  IconCheck,
  IconChevronDown,
  IconSearch,
  Spinner,
} from "@herohealthsoftware/ui";

type Option<T> = {
  label: string;
  render?: React.ReactNode;
  value: T;
};

type SelectSearchProps<T> = {
  label?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  searchLoading?: boolean;
  selected?: Option<T>;
  options: Option<T>[];
  onSearch: (query: string) => void;
  onSelect: (result: T) => void;
  onCancel: () => void;
  className?: string;
  helpText?: React.ReactNode;
};

export default function SelectSearch<T>(props: SelectSearchProps<T>) {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const resetSearch = () => {
    setShowDropdown(false);
    props.onCancel();
  };
  const outsideClickRef = useOutsideClick(resetSearch);

  return (
    <div className={props.className || ""} ref={outsideClickRef}>
      <div className="form-group">
        {props.label && <label className="control-label">{props.label}</label>}

        <button
          className="rounded px-3 py-2 border border-hero-blue-300 w-full shadow
            text-hero-blue-700"
          onClick={(event) => {
            event.preventDefault();
            showDropdown ? resetSearch() : setShowDropdown(true);
          }}
        >
          <div className="flex justify-between items-center h-4">
            {props.selected?.label || (
              <span className="text-hero-blue-400">{props.placeholder}</span>
            )}

            <div className="h-5 w-5 [&_path]:fill-hero-blue-500">
              <IconChevronDown />
            </div>
          </div>
        </button>
        {props.helpText}
      </div>

      {showDropdown && (
        <div
          className="absolute z-30 w-full bg-white border border-hero-blue-100
            rounded shadow-lg p-2 mt-1 top-16 mb-5"
        >
          <div className="form-group relative !mb-2">
            <input
              type="search"
              name="search-customers"
              className="form-control !px-8"
              placeholder={props.searchPlaceholder}
              onChange={(event) => props.onSearch(event.target.value)}
              autoFocus
            />

            <div className="absolute h-4 w-4 z-20 top-[9px] left-2.5 &_path:fill-hero-blue-400">
              <IconSearch />
            </div>

            {props.searchLoading && (
              <div className="absolute z-20 top-2 right-2.5">
                <Spinner className="h-4 w-4 fill-hero-blue-700" />
              </div>
            )}
          </div>

          <div>
            {props.options.length > 0
              ? props.options.map((option, index) => (
                  <OptionButton
                    key={index}
                    option={option}
                    selected={props.selected}
                    onClick={(result: Option<T>) => {
                      setShowDropdown(false);
                      props.onSelect(result.value);
                    }}
                  />
                ))
              : props.selected && (
                  <OptionButton
                    key="selected"
                    option={props.selected}
                    selected={props.selected}
                    onClick={() => null}
                  />
                )}
          </div>
        </div>
      )}
    </div>
  );
}

type OptionButtonProps<T> = {
  option: Option<T>;
  selected?: Option<T>;
  onClick: (option: Option<T>) => void;
  renderOption?: (option: Option<T>) => React.ReactNode;
};

function OptionButton<T>(props: OptionButtonProps<T>) {
  if (!props.option) return;

  const isSelected = isEqual(props.option, props.selected);

  return (
    <button
      className="px-3 py-1.5 rounded hover:bg-hero-blue-50 cursor-pointer w-full
        flex flex-col"
      onClick={(event) => {
        event.preventDefault();
        props.onClick(props.option);
      }}
      disabled={isSelected}
    >
      <div className="w-full flex justify-between items-center">
        {props.option.render || props.option.label}

        {isSelected && (
          <div className="h-4 w-4 [&_path]:fill-hero-blue-400">
            <IconCheck />
          </div>
        )}
      </div>
    </button>
  );
}
