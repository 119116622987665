import React, { useEffect, useRef, useState } from "react";
import { IconPlus1, IconX, Checkbox } from "@herohealthsoftware/ui";

import { translate } from "../../../../lib/i18n";
import useStripeSearch from "../../../../hooks/useStripeSearch";
import { formatProductPrice } from "../../../../lib/money";
import { EmisItem } from "../../../../lib/types";

type EmisItemsInputProps = {
  name: string;
  item: EmisItem;
  onChange: (item: EmisItem) => void;
};

export function EmisItemsInput(props: EmisItemsInputProps) {
  const focusRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [relevant, setRelevant] = useState([]);
  const { search, results, setResults } = useStripeSearch("product");

  const selected = !!props.item.selected;

  const resetSearch = () => {
    setResults([]);
    setShowDropdown(false);
  };

  const resultClassName =
    "flex items-center justify-between text-hero-blue-700 text-sm font-medium \
      cursor-pointer hover:bg-hero-blue-100 py-2 px-3";

  const searchResult = (key, product) => {
    return (
      <div
        key={key}
        className={resultClassName}
        onMouseDown={() => {
          resetSearch();
          props.onChange({
            ...props.item,
            product,
            invoiceItem: null,
          });
        }}
      >
        <div>{product.name}</div>
        <div>{formatProductPrice(product)}</div>
      </div>
    );
  };

  useEffect(() => {
    search(props.item.product_name, {
      min: 3,
      callback: (results) => {
        setRelevant(results.data || []);
      },
    });
  }, []);

  useEffect(() => {
    if (selected && !props.item.product && !props.item.invoiceItem) {
      focusRef.current.focus();
    }
  }, [selected, props.item.product, props.item.invoiceItem]);

  return (
    <li>
      <div>
        <label
          className="flex items-center gap-2 font-medium text-hero-blue-700 text-wrap"
          htmlFor={props.name}
        >
          <Checkbox
            id={props.name}
            name={props.name}
            checked={selected}
            onChange={() =>
              props.onChange({ ...props.item, selected: !selected })
            }
          />
          {props.item.product_name}
        </label>

        {selected && (props.item.product || props.item.invoiceItem) && (
          <div
            className="relative flex gap-2 items-center bg-hero-blue-500
              text-xs text-white px-2 py-1 mt-2 rounded-full w-fit max-w-md"
          >
            {props.item.product && (
              <div className="max-w-min truncate pr-4">
                {props.item.product.name}
              </div>
            )}

            {props.item.invoiceItem && (
              <div className="max-w-min truncate pr-4">
                {props.item.invoiceItem.description}
                <span className="text-xs font-bold ml-1 lowercase">
                  {translate("base.new")}
                </span>
              </div>
            )}

            <div
              onClick={() => {
                props.onChange({
                  ...props.item,
                  product: null,
                  invoiceItem: null,
                });
              }}
              className="[&_path]:fill-white h-3 w-3 cursor-pointer absolute right-2"
            >
              <IconX />
            </div>
          </div>
        )}

        {selected && !props.item.product && !props.item.invoiceItem && (
          <div
            onFocus={() => setShowDropdown(true)}
            onBlur={() => setShowDropdown(false)}
            className="form-group relative my-2"
          >
            <input
              ref={focusRef}
              type="search"
              onChange={(event) => search(event.target.value)}
              className="form-control"
            />

            {showDropdown && (
              <div
                className="flex flex-col absolute w-full z-20 my-2
                bg-white border border-hero-blue-100 rounded-lg shadow-lg"
              >
                <div
                  key="create-new-product"
                  className={resultClassName}
                  onMouseDown={() => {
                    resetSearch();
                    props.onChange({
                      ...props.item,
                      product: null,
                      invoiceItem: {
                        description: props.item.product_name,
                        quantity: 1,
                        unit_amount_in_pounds: "",
                        create: true,
                        mode: "create",
                      },
                    });
                  }}
                >
                  <div className="flex items-center gap-2">
                    <div className="h-6 w-6">
                      <IconPlus1 />
                    </div>
                    <div>
                      {translate("partners.stripe.createNewProductForItem")}
                    </div>
                  </div>
                </div>

                <hr className="border-top border-hero-blue-100" />

                {results.length > 0 && (
                  <h4 className="px-3 pt-2 uppercase text-xs text-hero-blue-500">
                    {translate("partners.stripe.searchResults")}
                  </h4>
                )}

                {results.map((product) =>
                  searchResult(`result-${product.stripe_id}`, product)
                )}

                {results.length > 0 && (
                  <hr className="border-top border-hero-blue-100" />
                )}

                <h4 className="px-3 pt-2 uppercase text-xs text-hero-blue-500">
                  {translate("partners.stripe.relevantMatches")}
                </h4>

                {relevant.length === 0 && (
                  <div className="px-3 pb-2 text-hero-blue-400">
                    {translate("partners.stripe.noRelevantMatches")}
                  </div>
                )}

                {relevant.map((product) =>
                  searchResult(`relevant-${product.stripe_id}`, product)
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </li>
  );
}
