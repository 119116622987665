import React, { useState } from "react";

import {
  Badge,
  Button,
  formatPatientNameStd,
  IconLink,
  InlineAlert,
  Link,
  Textarea,
  ThemeProvider,
  Typography,
} from "@herohealthsoftware/ui";
import ld from "lodash";
import { fetchCustomer } from "../../../../lib/customer";
import { formatISODateTime } from "../../../../lib/datetime";
import { translate } from "../../../../lib/i18n";
import {
  Customer,
  PatientRecord,
  PatientRecordCustomer,
  Practitioner,
} from "../../../../lib/types";
import * as Routes from "../../../../routes";
import Checkbox from "../../../atoms/Checkbox";
import DatePicker from "../../../atoms/DatePicker";
import PatientEntityCard from "../../../shared/PatientEntityCard";
import CustomerInput, { CustomerInputProps } from "./CustomerInput";
import InsuranceDetailsInput from "./InsuranceDetailsInput";
import ItemsInput, { ItemsInputProps } from "./ItemsInput";
import PaymentFields, { PaymentFieldsProps } from "./PaymentFields";
import PractitionerInput, { PractitionerInputProps } from "./PractitionerInput";

type InvoiceFormProps = PaymentFieldsProps &
  CustomerInputProps &
  ItemsInputProps &
  PractitionerInputProps & {
    customer_field_name: string;
    set_default_customer_field_name: string;
    set_default_customer_field_value: boolean;
    customer_field_editable: boolean;
    stripe_base_url: string;
    patient_record: PatientRecord;
    invoice: {
      diagnosis: string;
      memo: string;
      footer: string;
      confidential: boolean;
      insuranceDetails: string;
      service_date: string;
      insurer: string;
      authentication_code: string;
      membership_number: string;
      practitioner_id: number;
      appointment_id: number;
    };
    practitioner_options: Practitioner[];
    appointment_service_date: string;
    current_admin_practitioner_id: number;
    patient_record_customer: PatientRecordCustomer;
    default_customer: Customer;
    default_patient_record_customer: PatientRecordCustomer;
    invoice_errors: boolean;
    smart_invoice_matching: boolean;
    practitioner: Practitioner;
    location?: { name: string };
    practitioner_field_name: string;
  };

export type CollectionMethod = "request_payment" | "charge_customer";

export default function InvoiceForm(props: InvoiceFormProps) {
  const [customer, setCustomer] = useState(props.customer);
  const [defaultCustomer, setDefaultCustomer] = useState(
    props.default_customer
  );
  const [practitioner, setPractitioner] = useState(props.practitioner);

  const [diagnosis, setDiagnosis] = useState(!!props.invoice.diagnosis);
  const [diagnosisValue, setDiagnosisValue] = useState(
    props.invoice.diagnosis || ""
  );
  const [diagnosisError, setDiagnosisError] = useState("");
  const [items, setItems] = useState(props.items || []);
  const [collectionMethod, setCollectionMethod] = useState<CollectionMethod>(
    props.invoice.collection_method as CollectionMethod
  );
  const { insurer, authentication_code, confidential, membership_number } =
    props.invoice;
  const isInvoiceBillingDetailsPresent = [
    insurer,
    authentication_code,
    membership_number,
  ].every((value) => value === null);
  const invoiceErrors = props.invoice_errors;

  const billingNote =
    props.default_patient_record_customer?.invoice_setting_billing_note;
  const [insuranceDetails, setInsuranceDetails] = useState(
    !!props.invoice.insuranceDetails ||
      !!props.invoice.insurer ||
      !!props.invoice.authentication_code ||
      !!props.invoice.membership_number ||
      (!props.invoice.id &&
        ((isInvoiceBillingDetailsPresent &&
          !!props.default_patient_record_customer.invoice_setting_insurer) ||
          !!props.default_patient_record_customer
            .invoice_setting_authentication_code ||
          !!props.default_patient_record_customer
            .invoice_setting_membership_number))
  );

  const invoiceBillingDetailsDefaultValues = {
    insurer: invoiceErrors
      ? insurer
      : props.default_patient_record_customer.invoice_setting_insurer,
    authentication_code: invoiceErrors
      ? authentication_code
      : props.default_patient_record_customer
          .invoice_setting_authentication_code,
    membership_number: invoiceErrors
      ? membership_number
      : props.default_patient_record_customer.invoice_setting_membership_number,
  };

  const [confidentialValue, setConfidentialValue] = useState<boolean>(
    (props.invoice.id && confidential) ||
      (!props.invoice.id &&
        (props.invoice_errors
          ? confidential
          : props.default_patient_record_customer
              ?.invoice_setting_confidential))
  );

  const [isDiagnosis, setIsDiagnosis] = useState<boolean>(
    (props.invoice.id && diagnosis) ||
      (!props.invoice.id &&
        (props.invoice_errors
          ? diagnosis
          : props.default_patient_record_customer?.invoice_setting_diagnosis))
  );

  const [insuranceDetailsValue, setInsuranceDetailsValue] = useState(
    invoiceBillingDetailsDefaultValues
  );

  const [insuranceDetailsError, setInsuranceDetailsError] = useState("");
  const [appointment, setAppointment] = useState(props.appointment);
  const [loading, setLoading] = useState<string>("");

  const onReloadSourcesClick = async () => {
    const data = await fetchCustomer(customer);
    setCustomer(data);
  };

  const handleInsuranceDetailsChange = (fieldName, value) => {
    setInsuranceDetailsValue({
      ...insuranceDetailsValue,
      [fieldName]: value,
    });
  };

  const showOnStripeStatuses = (stripeStatuses) => {
    return stripeStatuses.includes(props.invoice.stripe_status);
  };

  const handleFormSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let isError = false;
    const mode = e.currentTarget.value;

    if (insuranceDetails) {
      if (
        !insuranceDetailsValue.insurer &&
        !insuranceDetailsValue.authentication_code &&
        !insuranceDetailsValue.membership_number
      ) {
        isError = true;
        e.preventDefault();
        setInsuranceDetailsError(
          translate("partners.stripe.insuranceDetailsError")
        );
      } else {
        setInsuranceDetailsError("");
      }
    }
    if (isDiagnosis) {
      if (!diagnosisValue.trim()) {
        isError = true;
        e.preventDefault();
        setDiagnosisError(translate("partners.stripe.diagnosisError"));
      } else {
        setDiagnosisError("");
      }
    }

    if (!isError) {
      setLoading(mode);
      const form = document.getElementById(
        "stripe-invoice-form"
      ) as HTMLFormElement;
      if (form) {
        const modeParam = form.querySelector(
          "#mode-param-field"
        ) as HTMLInputElement;
        if (modeParam) {
          modeParam.value = mode;
        }

        form.submit();
      }
    }
  };
  return (
    <ThemeProvider>
      <div className="flex flex-col gap-5 mb-4">
        {billingNote && (
          <div>
            <InlineAlert
              variant="info"
              label={translate("partners.stripe.billingNote")}
            >
              <div className="break-word">
                <p>{billingNote}</p>
              </div>
            </InlineAlert>
          </div>
        )}

        <PatientEntityCard
          label={translate("base.patient")}
          patient_record={props.patient_record}
        />

        <CustomerInput
          customer={customer}
          setCustomer={setCustomer}
          defaultCustomer={defaultCustomer}
          name={props.customer_field_name}
          defaultFieldName={props.set_default_customer_field_name}
          defaultFieldValue={props.set_default_customer_field_value}
          editable={props.customer_field_editable}
          stripe_base_url={props.stripe_base_url}
          isStatusOpenOrPaid={showOnStripeStatuses(["open", "paid"])}
        />
      </div>

      <h4 className="text-xl font-bold mb-5">
        {translate("partners.stripe.items")}
      </h4>

      <ItemsInput
        items={items}
        editable={props.invoice.stripe_status === "draft"}
        setItems={setItems}
        coupons={props.coupons}
        patient_record={props.patient_record}
        config={{
          resource: "invoice",
          inputNamePrefix: "partners_stripe_invoice[invoice_items_attributes]",
          oneTimeItems: true,
          coupons: true,
          totals: true,
          emisItems: {
            enabled: props.patient_record.ehr_partner === "emis",
            patient_record: props.patient_record,
          },
        }}
      />

      {showOnStripeStatuses([
        "draft",
        "uncollectible",
        "unknown",
        "void",
        "refunded",
        "credit",
      ]) && (
        <PaymentFields
          customer={customer}
          setCustomer={setCustomer}
          invoice={props.invoice}
          defaultCustomer={defaultCustomer}
          setDefaultCustomer={setDefaultCustomer}
          collectionMethod={collectionMethod}
          setCollectionMethod={setCollectionMethod}
          collection_method_field_name={props.collection_method_field_name}
          payment_source={props.payment_source}
          payment_source_field_name={props.payment_source_field_name}
          stripe_publishable_key={props.stripe_publishable_key}
          stripe_account={props.stripe_account}
          onReloadSourcesClick={onReloadSourcesClick}
          stripeBaseUrl={props.stripe_base_url}
        />
      )}

      <div className="py-6">
        <div className="flex align-center text-xl leading-7 font-bold text-hero-blue-700 mb-4 gap-x-2">
          {translate("partners.stripe.additionalOptions")}
        </div>
        <InsuranceDetailsInput
          insuranceDetails={insuranceDetails}
          insuranceDetailsError={insuranceDetailsError}
          setInsuranceDetails={setInsuranceDetails}
          handleInsuranceDetailsChange={handleInsuranceDetailsChange}
          invoice={props.invoice}
          invoiceBillingDetailsDefaultValues={
            invoiceBillingDetailsDefaultValues
          }
        />
        <div className="mb-6">
          <Checkbox
            id="diagnosis"
            name="diagnosis_checkbox"
            value="3"
            label={translate("partners.stripe.diagnosis")}
            defaultChecked={isDiagnosis}
            handleChange={(e) => {
              showOnStripeStatuses([
                "draft",
                "uncollectible",
                "unknown",
                "void",
                "open",
                "paid",
              ]) && setIsDiagnosis(e.target.checked);
            }}
          />
          {isDiagnosis ? (
            <div className="ml-6 my-5">
              <Textarea
                className={`form-control text optional mt-2 ${
                  showOnStripeStatuses(["refunded", "credit"]) &&
                  "bg-hero-blue-50"
                }`}
                name="partners_stripe_invoice[diagnosis]"
                id="partners_stripe_invoice_diagnosis"
                defaultValue={props.invoice.diagnosis}
                onChange={(e) => {
                  setDiagnosisValue(e.target.value);
                }}
                disabled={showOnStripeStatuses(["refunded", "credit"])}
              />
              {diagnosisError && (
                <div className="text-red-600 text-base mt-1">
                  {diagnosisError}
                </div>
              )}
            </div>
          ) : (
            <>
              <input
                value=""
                type="hidden"
                name="partners_stripe_invoice[diagnosis]"
              />
            </>
          )}
        </div>

        <input
          value="0"
          type="hidden"
          name="partners_stripe_invoice[confidential]"
        />
        <Checkbox
          id="partners_stripe_invoice_confidential"
          name="partners_stripe_invoice[confidential]"
          value="1"
          label={translate("partners.stripe.confidentialOnInvoice")}
          subtext={translate(
            "partners.stripe.patientInformationWillNotBeShown"
          )}
          defaultChecked={confidentialValue}
          disabled={showOnStripeStatuses(["paid", "open"])}
        />
      </div>
      <div className="py-4">
        <div className="flex align-center text-xl leading-7 font-bold text-hero-blue-700 gap-x-2">
          {translate("partners.stripe.linkToAService")}
        </div>
        <div className="text-md leading-7 text-hero-blue-400">
          {translate("partners.stripe.linkInvoice")}
        </div>
      </div>
      <div className="flex gap-3 mb-5">
        <div>
          <div className="flex align-center text-base leading-7 font-bold text-hero-blue-700 gap-x-2">
            {translate("partners.stripe.practitioner")}
            {!props.smart_invoice_matching && (
              <span className="flex my-auto font-normal">
                <Badge size={"sm"}>{translate("base.optional")}</Badge>
              </span>
            )}
          </div>
          <div>
            <PractitionerInput
              setPractitioner={setPractitioner}
              practitioner={practitioner}
              appointment={appointment}
              practitionerOptions={props.practitioner_options}
              practitionerFieldName={props.practitioner_field_name}
              currentAdminPractitionerId={props.current_admin_practitioner_id}
              isStatusDraftOrOpen={showOnStripeStatuses(["draft", "open"])}
              smartInvoiceMatching={props.smart_invoice_matching}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label className="font-bold text-hero-blue-700 text-base mb-1">
            {translate("partners.stripe.serviceDate")}
          </label>
          <div>
            {appointment ? (
              <>
                <input
                  value={props.appointment_service_date}
                  type="hidden"
                  name="partners_stripe_invoice[service_date]"
                />
                <DatePicker
                  id="partners_stripe_invoice_service_date"
                  defaultValue={
                    props.invoice.id
                      ? props.invoice.service_date
                      : appointment
                      ? props.appointment_service_date
                      : null
                  }
                  disabled={true}
                />
              </>
            ) : (
              <DatePicker
                name="partners_stripe_invoice[service_date]"
                id="partners_stripe_invoice_service_date"
                defaultValue={
                  props.invoice.id ? props.invoice.service_date : null
                }
                disabled={
                  !!(props.invoice.id && appointment) ||
                  !!showOnStripeStatuses([
                    "refunded",
                    "credit",
                    "uncollectible",
                    "unknown",
                    "void",
                    "paid",
                  ])
                }
              />
            )}
          </div>
        </div>
        <input
          value={appointment ? appointment.id : ""}
          type="hidden"
          name="partners_stripe_invoice[appointment_id]"
        />
        <input value="" type="hidden" name="mode" id="mode-param-field" />
      </div>

      {props.smart_invoice_matching && !appointment && (
        <div className="mb-8">
          <InlineAlert
            variant="info"
            label={translate("partners.stripe.smartInvoicingEnabled")}
          >
            <div className="break-word">
              <p>{translate("partners.stripe.smartInvoicingInformation")}</p>
            </div>
          </InlineAlert>
        </div>
      )}

      <div>
        {appointment && practitioner && location && (
          <>
            {!props.smart_invoice_matching && (
              <div className="mb-4">
                <InlineAlert
                  variant="info"
                  label={translate("partners.stripe.alertInfo", {
                    description: appointment.description,
                  })}
                />
              </div>
            )}

            <div className="p-4 border border-hero-blue-200 font-medium rounded-lg mb-8">
              <div className="grid grid-cols-8">
                <div className="col-span-2 flex flex-col justify-center">
                  <div>{formatISODateTime(appointment.start_time, "date")}</div>

                  <div>{formatISODateTime(appointment.start_time, "time")}</div>

                  <div className="flex font-normal">
                    <Badge size={"sm"}>
                      {appointment.deleted_at
                        ? translate("base.cancelled")
                        : translate("base.confirmed")}
                    </Badge>
                  </div>
                </div>
                <div
                  className={`flex flex-col justify-between
                  ${
                    props.smart_invoice_matching ? "col-span-4" : "col-span-6"
                  }`}
                >
                  <Link variant="secondary" className="-herolib-text-lg mb-1">
                    <a href={Routes.admin_appointment_path(appointment.id)}>
                      {appointment.description}
                    </a>
                  </Link>
                  <Typography weight="normal" color="light" size="sm">
                    <p>
                      {ld.capitalize(translate("base.for"))}{" "}
                      <Link variant="secondary">
                        <a
                          href={Routes.patient_dashboard_path(
                            props.patient_record.client_id
                          )}
                        >
                          {formatPatientNameStd({
                            title: ld.capitalize(props.patient_record.title),
                            firstName: props.patient_record.first_name,
                            lastName: props.patient_record.last_name,
                          })}
                        </a>
                      </Link>{" "}
                      {translate("base.with")}{" "}
                      {translate("base.practitionerAtLocation", {
                        practitioner: practitioner.type_and_full_name,
                        location: props.location.name,
                      })}
                    </p>
                  </Typography>
                </div>
                {props.smart_invoice_matching && (
                  <div
                    className="col-span-2 flex justify-end my-auto"
                    onClick={() => {
                      setAppointment(null);
                      setPractitioner(null);
                    }}
                  >
                    <Button
                      variant="white"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setAppointment(null);
                        setPractitioner(null);
                      }}
                      prepend={<IconLink />}
                    >
                      {translate("partners.stripe.unlink")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <div className="flex justify-end gap-2">
        <Button
          type="submit"
          name="mode"
          value="draft"
          variant={`${showOnStripeStatuses(["paid"]) ? "primary" : "white"}`}
          onClick={handleFormSubmit}
          loading={loading === "draft"}
          disabled={!!loading}
        >
          {showOnStripeStatuses(["open", "paid"])
            ? translate("partners.stripe.updateInvoice")
            : translate("partners.stripe.saveDraft")}
        </Button>

        {showOnStripeStatuses([
          "open",
          "draft",
          "refunded",
          "credit",
          "uncollectible",
          "unknown",
          "void",
        ]) &&
          (collectionMethod === "charge_customer" ||
          showOnStripeStatuses(["open"]) ? (
            <Button
              type="submit"
              name="mode"
              value="charge"
              variant="primary"
              onClick={handleFormSubmit}
              loading={loading === "charge"}
              disabled={!!loading}
            >
              {translate("partners.stripe.payNow")}
            </Button>
          ) : (
            <Button
              type="submit"
              name="mode"
              value="finalize"
              variant="primary"
              onClick={handleFormSubmit}
              loading={loading === "finalize"}
              disabled={!!loading}
            >
              {translate("partners.stripe.finalizeAndSendInvoice")}
            </Button>
          ))}
      </div>
    </ThemeProvider>
  );
}
