import React from "react";
import HeroHealthMessageBuilder from "@herohealthsoftware/message-builder-react";
import { Spinner, ThemeProvider } from "@herohealthsoftware/ui";

const MessageBuilderPage = ({
  env,
  apiKey,
  tenantId,
  patientId,
  adminId,
  taskId,
  kinde_jwt_id_token,
}) => {
  return (
    <div>
      <HeroHealthMessageBuilder
        env={env === "production" ? "production" : "staging"}
        tenantId={tenantId}
        patientId={patientId}
        adminId={adminId}
        taskId={taskId}
        jwtToken={kinde_jwt_id_token}
        telemetry
        loader={
          <ThemeProvider>
            <Spinner className="h-12 w-12" />
          </ThemeProvider>
        }
      />
    </div>
  );
};

export default MessageBuilderPage;
