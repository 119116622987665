import {
  Button,
  EntityCard,
  IconExternalLink,
  IconPlus1,
  Link,
  Typography,
} from "@herohealthsoftware/ui";
import React from "react";
import { translate } from "../../../../lib/i18n";
import { PatientRecord } from "../../../../lib/types";
import * as Routes from "../../../../routes";

type SignatureRxProps = {
  patient_record: PatientRecord;
  settings: { dashboard_url: string };
};

export default function SignatureRx(props: SignatureRxProps) {
  const canCreatePrescription = !!props.patient_record.ehr_partner_id;

  return (
    <div className="flex flex-col gap-2 py-4 px-6">
      <EntityCard
        label="Patient"
        title={props.patient_record.name_std}
        information={{
          [translate("base.email")]: props.patient_record.contact_email,
        }}
        {...props}
      />

      <Link type="primary">
        <a
          href={props.settings.dashboard_url}
          target="_blank"
          rel="noreferrer"
          className="flex items-center gap-1 no-underline mb-5 w-fit"
        >
          <span>{translate("partners.signatureRx.goToDashboard")}</span>
          <div className="h-4 w-4 [&_path]:fill-hero-primary-700">
            <IconExternalLink />
          </div>
        </a>
      </Link>

      {canCreatePrescription ? (
        <Button
          as="a"
          type="primary"
          disabled={!canCreatePrescription}
          href={
            canCreatePrescription
              ? Routes.admin_prescription_builder_path({
                  patient_id: props.patient_record.client_id,
                })
              : undefined
          }
        >
          <div className="flex items-center gap-2 no-underline">
            <div className="h-4 w-4">
              <IconPlus1 />
            </div>
            {translate("partners.signatureRx.createPrescription")}
          </div>
        </Button>
      ) : (
        <Typography color="secondary" size="sm" weight="semibold">
          <p
            className="flex items-center justify-center text-center
              w-full px-6 py-4 border border-hero-blue-200 rounded-lg"
          >
            {translate("partners.signatureRx.createPrescriptionDisabled")}
          </p>
        </Typography>
      )}
    </div>
  );
}
