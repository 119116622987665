import React, { useState } from "react";

import {
  Badge,
  Button,
  IconExclamation,
  IconSearch,
  Link,
  Spinner,
} from "@herohealthsoftware/ui";
import useStripeSearch from "../../../../hooks/useStripeSearch";
import { translate } from "../../../../lib/i18n";
import { Customer } from "../../../../lib/types";
import Toggle from "../../../atoms/Toggle";
import CustomerEntityCard from "../CustomerEntityCard";
import CreateCustomerModal from "./CreateCustomerModal";
import CustomerSearchResults from "./CustomerSearchResults";

export type CustomerInputProps = {
  customer?: Customer;
  defaultCustomer?: Customer;
  setCustomer: (customer: Customer) => void;
  name: string;
  defaultFieldName: string;
  defaultFieldValue: boolean;
  editable: boolean;
  stripe_base_url: string;
  isStatusOpenOrPaid?: boolean;
};

export default function CustomerInput(props: CustomerInputProps) {
  const { search, loading, results, setResults } = useStripeSearch("customer");

  const [showCustomerSearchResults, setShowCustomerSearchResults] = useState(
    !props.customer
  );
  const [searchValue, setSearchValue] = useState("");
  const [showCustomerSearch, setShowCustomerSearch] = useState(!props.customer);
  const [createCustomerOpen, setCreateCustomerOpen] = useState(false);
  const [usePatientsDefaultCustomer, setUsePatientsDefaultCustomer] = useState(
    props.defaultCustomer &&
      props.defaultCustomer.stripe_id === props.customer?.stripe_id
  );

  const handleUsePatientsDefaultCustomerChange = () => {
    if (usePatientsDefaultCustomer) {
      setUsePatientsDefaultCustomer(false);
      setShowCustomerSearch(true);
      setShowCustomerSearchResults(true);
      props.setCustomer(undefined);
    } else {
      setUsePatientsDefaultCustomer(true);
      props.setCustomer(props.defaultCustomer);
    }
  };

  const resetCustomerSearch = (customer) => {
    setShowCustomerSearch(!customer);
    setShowCustomerSearchResults(false);
    setResults([]);
    setSearchValue("");
    setUsePatientsDefaultCustomer(
      !!props.defaultCustomer?.stripe_id
        ? customer?.stripe_id === props.defaultCustomer?.stripe_id
        : false
    );
  };

  const handleResultClick = (result) => {
    props.setCustomer(result);
    resetCustomerSearch(result);
    setShowCustomerSearch(false);
    setShowCustomerSearchResults(false);
  };

  const handleRefreshButton = () => {
    search(searchValue);
  };

  return (
    <>
      {props.defaultCustomer && (
        <Toggle
          checked={usePatientsDefaultCustomer}
          onChange={handleUsePatientsDefaultCustomerChange}
          disabled={props.isStatusOpenOrPaid}
        >
          {translate("partners.stripe.usePatientDefaultStripeCustomer")}
          <span className="font-normal text-hero-blue-400 whitespace-nowrap flex gap-1">
            {translate("base.default")}:
            <Link variant="secondary" className="!text-hero-blue-400">
              <a
                href={`${props.stripe_base_url}/customers/${props.defaultCustomer.stripe_id}`}
                target="_blank"
              >
                {props.defaultCustomer.name}
              </a>
            </Link>
            {!props.defaultCustomer.email ? (
              <Badge size="sm" variant="warning" icon={<IconExclamation />}>
                {translate("partners.stripe.noEmailAddress")}
              </Badge>
            ) : (
              `(${props.defaultCustomer.email})`
            )}
          </span>
        </Toggle>
      )}

      <input
        type="hidden"
        name={props.name}
        value={props.customer ? props.customer?.stripe_id : ""}
      />

      {usePatientsDefaultCustomer || (
        <div className="relative m-0">
          {showCustomerSearch && (
            <div className="mb-4">
              <label className="z-50 relative m-0 w-80">
                <div className="text-xl leading-7 font-bold text-hero-blue-700">
                  {translate("partners.stripe.customer")}
                </div>

                <div className="mt-5 relative">
                  <div className="w-5 h-5 z-20 absolute top-2 left-2 [&_path]:fill-hero-blue-400">
                    <IconSearch />
                  </div>

                  <input
                    className="text-sm placeholder-hero-blue-400 text-hero-blue-700 leading-5 font-normal h-9 w-80 pl-10 pr-3 py-2 border border-hero-blue-300 rounded-md drop-shadow-sm
                    focus-visible:outline-none focus-visible:border-2 focus-visible:border-hero-blue-500 focus-visible:ring focus-visible:ring-turquoise-focus focus-visible:-ml-px focus-visible:mr-px focus-visible:pr-2.5"
                    type="search"
                    onFocus={() => {
                      setShowCustomerSearchResults(true);
                      setShowCustomerSearch(true);
                    }}
                    value={searchValue}
                    onChange={(event) => {
                      search(event.target.value);
                      setSearchValue(event.target.value);
                    }}
                    placeholder={translate("partners.stripe.searchCustomers")}
                    autoFocus={!usePatientsDefaultCustomer}
                  />

                  {loading && (
                    <div className="absolute z-20 top-2 right-2.5">
                      <Spinner className="h-4 w-4 fill-hero-blue-700" />
                    </div>
                  )}
                </div>
              </label>

              {showCustomerSearchResults && (
                <>
                  <div
                    onClick={() => resetCustomerSearch(props.customer)}
                    className="z-40 bg-white fixed top-0 left-0 w-screen h-screen opacity-80"
                  ></div>

                  <div className="absolute mt-2 w-96 bg-white z-50 border border-gray-100 shadow-md py-1 rounded-md">
                    <CustomerSearchResults
                      results={results}
                      searchValue={searchValue}
                      handleResultClick={handleResultClick}
                      handleRefreshButton={handleRefreshButton}
                      resetCustomerSearch={resetCustomerSearch}
                      customer={props.customer}
                      stripe_base_url={props.stripe_base_url}
                      setCreateCustomerOpen={setCreateCustomerOpen}
                    />
                  </div>
                </>
              )}
            </div>
          )}

          {createCustomerOpen && (
            <CreateCustomerModal
              resetCustomerSearch={resetCustomerSearch}
              customer={props.customer}
              createCustomerOpen={createCustomerOpen}
              setCreateCustomerOpen={setCreateCustomerOpen}
              setCustomer={props.setCustomer}
              setShowCustomerSearch={setShowCustomerSearch}
              setShowCustomerSearchResults={setShowCustomerSearchResults}
            />
          )}

          {!showCustomerSearch && !showCustomerSearchResults && (
            <>
              <CustomerEntityCard
                customer={props.customer}
                labelAction={
                  props.editable && (
                    <Button
                      variant="white"
                      size="xs"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setShowCustomerSearch(true);
                        setShowCustomerSearchResults(true);
                        resetCustomerSearch(null);
                      }}
                    >
                      {translate("base.change")}
                    </Button>
                  )
                }
              />

              {props.editable && props.customer?.email && (
                <div className="form-group boolean">
                  <input
                    type="hidden"
                    name={props.defaultFieldName}
                    value="0"
                  />

                  <label
                    className="m-0 boolean optional flex items-center font-medium text-hero-blue-700"
                    htmlFor="set_default_customer"
                  >
                    <input
                      type="checkbox"
                      value="1"
                      name={props.defaultFieldName}
                      id="set_default_customer"
                      className="boolean mr-2"
                      defaultChecked={props.defaultFieldValue}
                    />
                    {translate("partners.stripe.setDefaultCustomer")}
                  </label>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
