import React from "react";
import Booking from "@herohealthsoftware/booking-react";
import { Spinner, ThemeProvider } from "@herohealthsoftware/ui";

const LinkBuilder = ({
  env,
  kindeJwtIdToken,
  tenantId,
  patientId,
  locationId,
  practitionerId,
  heroBooking,
  stripeBooking,
  onBook,
  rescheduleAppointmentId,
  reschedulePracitceGroupId,
}) => {
  return (
    <div>
      {heroBooking || stripeBooking ? (
        <Booking
          env={env === "production" ? "production" : "staging"}
          jwtToken={kindeJwtIdToken}
          tenantId={tenantId}
          patientId={patientId?.toString() || null}
          practiceGroupId={null}
          locationId={locationId?.toString() || null}
          appointmentTypeId={null}
          practitionerId={practitionerId?.toString() || null}
          features={{
            reserveOnly: true,
            crossOrg: false,
          }}
          onReserve={(appointment) => {
            window.location.replace(
              heroBooking
                ? `/appointments/${appointment.appointment_id}/payment/new?raise_invoice_flag=true`
                : `/partners/stripe/admins/invoices/new?appointment_id=${appointment.appointment_id}`
            );
          }}
          reschedulePracitceGroupId={
            reschedulePracitceGroupId?.toString() || null
          }
          rescheduleAppointmentId={rescheduleAppointmentId?.toString() || null}
          loader={
            <ThemeProvider>
              <Spinner className="h-12 w-12" />
            </ThemeProvider>
          }
        />
      ) : (
        <Booking
          env={env === "production" ? "production" : "staging"}
          jwtToken={kindeJwtIdToken}
          tenantId={tenantId}
          patientId={patientId?.toString() || null}
          practiceGroupId={null}
          locationId={locationId?.toString() || null}
          appointmentTypeId={null}
          practitionerId={practitionerId?.toString() || null}
          onBook={() => window.location.replace(onBook)}
          reschedulePracitceGroupId={
            reschedulePracitceGroupId?.toString() || null
          }
          rescheduleAppointmentId={rescheduleAppointmentId?.toString() || null}
          loader={
            <ThemeProvider>
              <Spinner className="h-12 w-12" />
            </ThemeProvider>
          }
        />
      )}
    </div>
  );
};

export default LinkBuilder;
