import React, { useState, useEffect } from "react";
import { Select } from "@herohealthsoftware/ui";

const hoursOptions = Array.from({ length: 24 }, (_, i) => ({
  value: i.toString().padStart(2, "0"),
  label: i.toString().padStart(2, "0"),
}));

const minutesOptions = Array.from([0, 15, 30, 45], (i) => ({
  value: i.toString().padStart(2, "0"),
  label: i.toString().padStart(2, "0"),
}));

const TimeInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
  menuPlacementTop: boolean
}> = ({ value, onChange, menuPlacementTop }) => {
  const [hours, setHours] = useState(value.split(":")[0] || "");
  const [minutes, setMinutes] = useState(value.split(":")[1] || "");

  useEffect(() => {
    const formattedValue =
      hours && minutes
        ? `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`
        : "";
    if (formattedValue !== value) {
      onChange(formattedValue);
    }
  }, [hours, minutes, onChange]);

  return (
    <div className="flex items-center gap-1">
      <Select
        className="w-[72px]"
        value={hours}
        options={hoursOptions}
        onChange={(value: string) => setHours(value)}
        menuPosition="fixed"
        menuShouldBlockScroll
        placeholder="--"
        menuPlacement={menuPlacementTop ? "top" : "bottom"}
      />
      <span>:</span>
      <Select
        className="w-[72px]"
        value={minutes}
        options={minutesOptions}
        onChange={(value) => setMinutes(value)}
        menuPosition="fixed"
        menuShouldBlockScroll
        placeholder="--"
        menuPlacement={menuPlacementTop ? "top" : "bottom"}
      />
    </div>
  );
};

export default TimeInput;
