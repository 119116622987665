import React, { useState } from "react";
import {
  formatPatientDobStd,
  formatPatientNameStd,
  Select,
} from "@herohealthsoftware/ui";
import { Appointment, Practitioner } from "../../../../lib/types";
import ld from "lodash";

export type PractitionerInputProps = {
  practitionerFieldName: string;
  appointment: Appointment;
  currentAdminPractitionerId: number;
  smartInvoiceMatching: boolean;
  isStatusDraftOrOpen?: boolean;
  setPractitioner: (practitioner: Practitioner) => void;
  practitioner: Practitioner;
  practitionerOptions: Practitioner[];
};

export default function PractitionerInput(props: PractitionerInputProps) {
  const appointment = props.appointment;

  const options = props.practitionerOptions.map((option: Practitioner) => ({
    value: option.id,
    label: option.type_and_full_name,
  }));

  const defaultValue = () => {
    if (props.practitioner) {
      return String(props.practitioner.id);
    } else if (appointment) {
      return String(appointment.practitioner_id);
    } else if (props.currentAdminPractitionerId) {
      return String(props.currentAdminPractitionerId);
    } else {
      return null;
    }
  };

  const handleSetPractitioner = (value: string) => {
    const practitioner = props.practitionerOptions.find(
      (practitioner) => practitioner.id === parseInt(value)
    );
    props.setPractitioner(practitioner || null);
  };

  return (
    <div className="w-80">
      <Select
        name={props.practitionerFieldName}
        key={props.practitioner?.id || "practitionerSelect"}
        value={props.practitioner?.id || defaultValue() || ""}
        options={options}
        onChange={(value) => {
          handleSetPractitioner(value);
        }}
        isDisabled={(appointment || !props.isStatusDraftOrOpen) && true}
        className="w-full"
        renderOption={(option) => {
          return (
            <div className="px-3 py-1.5 cursor-pointer hover:bg-hero-blue-50">
              <div className="text-xs text-hero-blue-500">{option.label}</div>
            </div>
          );
        }}
      />
    </div>
  );
}
