import React, { useState, useEffect } from 'react';
import {
  Badge,
  TextInput,
} from '@herohealthsoftware/ui';
import { translate } from '../../lib/i18n';
import { DefaultURLAndName } from '../../lib/types'

const PartnerSubmission = (
  { tempSubmissionType, submissionErrors, index, setTempSubmissionType }
) => {
  const [defaultURLAndName, setDefaultURLAndName] = useState<DefaultURLAndName>({ name: "", url: "" });

  useEffect(() => {
    if(!tempSubmissionType.external_url && !tempSubmissionType.external_url_name){
      setDefaultURLAndName(
        { name: tempSubmissionType.partner.external_url_name,
          url: tempSubmissionType.partner.external_url
        }
      );
      setTempSubmissionType({
        ...tempSubmissionType,
        external_url_name: defaultURLAndName.name,
        external_url: defaultURLAndName.url,
      });
    }
  }, [tempSubmissionType]);

  return (
    <div className='flex-row justify-between gap-2'>
      <div className='w-full'>
        <label
          className='text-hero-blue-700 text-sm font-bold mb-2'
          htmlFor={`care_nav_pathway_care_nav_submission_types_attributes_${index}_external_url`}
        >
          {tempSubmissionType.partner.label ? tempSubmissionType.partner.label : tempSubmissionType.partner.name}{' '}
          {translate('careNavigation.pathway.url')}
        </label>
        <TextInput
          defaultValue={tempSubmissionType.external_url}
          error={
            submissionErrors && submissionErrors['external_url']
          }
          placeholder={
            tempSubmissionType.partner.placeholder
            ? tempSubmissionType.partner.placeholder
            : translate('careNavigation.pathway.urlWebsitePlaceholder')
          }
          size='md'
          name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][external_url]`}
          id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_external_url`}
          onChange={(ev) => {
            setTempSubmissionType({
              ...tempSubmissionType,
              external_url: ev.currentTarget.value,
            });
          }}
        />
      </div>
      <div className='w-full'>
        <label
          className='mb-[7px]'
          htmlFor={`care_nav_pathway_care_nav_submission_types_attributes_${index}_external_url`}
        >
          {translate('careNavigation.pathway.name')}

          <Badge
            colour='neutral'
            size='sm'
            variant='custom'
            className='ml-2'
          >
            {translate('careNavigation.pathway.optional')}
          </Badge>
        </label>
        <TextInput
          defaultValue={tempSubmissionType.external_url_name}
          error={
            submissionErrors &&
            submissionErrors['external_url_name']
          }
          placeholder={translate(
            'careNavigation.pathway.urlWebsiteNamePlaceholder'
          )}
          size='md'
          name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][external_url_name]`}
          id={`care_nav_pathway_care_nav_submission_types_attributes_${index}_external_url_name`}
          onChange={(ev) => {
            setTempSubmissionType({
              ...tempSubmissionType,
              external_url_name: ev.currentTarget.value,
            });
          }}
        />
      </div>
      <input
        type='hidden'
        value={tempSubmissionType.partner.id}
        name={`care_nav_pathway[care_nav_submission_types_attributes][${index}][partner_id]`}
      />
    </div>
  );
};

export default PartnerSubmission;
