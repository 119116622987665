import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import DataTable from "./DataTable";

const appointmentsBoookedTable = (props) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable props={props} />
    </QueryClientProvider>
  );
};

export default appointmentsBoookedTable;
