import React from 'react';
import {DropdownMenuItem} from '@herohealthsoftware/ui';
import type { CareNavigationSubmissionType, CareNavigationPartner } from './Pathway';

interface ExternalAppOptionsProps {
  onAddAnotherClick?: (data: { type: string; partner: CareNavigationPartner }) => void;
  onSaveAndAddAnotherClick?:(
    (tempSubmissionTypeData: CareNavigationSubmissionType, data: { type: string; partner: CareNavigationPartner }) => void
  );
  tempSubmissionType?: CareNavigationSubmissionType;
  saveAndAdd: boolean;
  careNavPartners: CareNavigationPartner[]
}

const ExternalAppOptions: React.FC<ExternalAppOptionsProps> = ({
  onAddAnotherClick,
  onSaveAndAddAnotherClick,
  tempSubmissionType,
  saveAndAdd,
  careNavPartners
}) => {
  const handleClick = (partner: CareNavigationPartner) => {
    if(saveAndAdd){
      onSaveAndAddAnotherClick(tempSubmissionType,{
        type: 'partner',
        partner,
      });
    }else{
      onAddAnotherClick({
        type: 'partner',
        partner,
      });
    }
  };

  return (
    <>
      {careNavPartners?.map((partner) => (
        <DropdownMenuItem key={partner.id} onClick={() => handleClick(partner)}>
          {partner.label ? partner.label : partner.name}
        </DropdownMenuItem>
      ))}
    </>
  );
};

export default ExternalAppOptions;
