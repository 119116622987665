import {
  Button,
  ButtonGroup,
  ErrorSummary,
  IconArrowNarrowRight,
  Spinner,
} from "@herohealthsoftware/ui";
import React, { useState } from "react";
import { formatISODateTime } from "../../../../lib/datetime";
import Hero from "../../../../lib/hero";
import { translate } from "../../../../lib/i18n";
import { formatPence } from "../../../../lib/money";
import { Appointment, PatientRecord } from "../../../../lib/types";
import * as Routes from "../../../../routes";
import StatusBadge from "../../../partners/stripe/StatusBadge";
import PatientEntityCard from "../../../shared/PatientEntityCard";

type LegacyInvoice = {
  id: number;
  invoice_id: string;
  created_at: string;
  total_amount_in_pence: number;
  status: string;
};

type LegacyInvoicingProps = {
  invoices: LegacyInvoice[];
  pagination: {
    total_pages: number;
    current_page: number;
  };
  patient_record: PatientRecord;
  appointment: Appointment;
  settings: { path: string };
};

export default function LegacyInvoicing(props: LegacyInvoicingProps) {
  const [invoices, setInvoices] = useState<LegacyInvoice[]>(props.invoices);
  const [pagination, setPagination] = useState(props.pagination);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handlePagination = (page: number) => () => {
    if (page < 1 || page > pagination.total_pages) {
      return;
    }

    fetchInvoices(page);
  };

  const fetchInvoices = async (page: number) => {
    setLoading(true);
    setError(null);

    try {
      const response = await Hero.fetch(
        Routes.partners_stripe_admins_legacy_invoices_path({
          patient_id: props.patient_record.client_id,
          page,
        })
      );

      const data = await response.json();
      setInvoices(data.invoices);
      setPagination(data.pagination);
    } catch (error) {
      console.error(error);
      setError(translate("base.unexpectedError"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-6 py-4 px-6 h-[calc(100vh-100px)] overflow-y-scroll">
      <PatientEntityCard patient_record={props.patient_record} />

      {error && <ErrorSummary items={[{ message: error }]} />}

      {loading && (
        <div
          className="flex flex-col justify-center items-center p-6
                border border-hero-blue-200 rounded-lg text-hero-blue-700"
        >
          <Spinner className="w-8 h-8 mb-2 fill-hero-blue-700" />

          <div className="leading-6 font-medium">
            {translate("partners.stripe.loadingLegacyInvoices")}
          </div>
        </div>
      )}

      {invoices.length === 0 && !loading && !error && (
        <div
          className="flex flex-col justify-center items-center p-6
                border border-hero-blue-200 rounded-lg text-hero-blue-700"
        >
          <div className="leading-6 font-medium">
            {translate("partners.stripe.noLegacyInvoices")}
          </div>
        </div>
      )}

      {invoices.length > 0 && !loading && (
        <>
          <div className="border border-hero-blue-200 rounded-lg">
            {invoices.map((invoice) => (
              <div
                key={invoice.id}
                onClick={() => {
                  window.location.href = Routes.invoice_path(invoice.id);
                }}
                className="group px-4 py-3 border-b border-hero-blue-200 last:border-b-0
                    cursor-pointer hover:bg-hero-blue-700/5 last:rounded-b-lg"
              >
                <div className="flex justify-between text-hero-blue-700 leading-6 font-bold mb-1">
                  <div className="flex flex-row items-center gap-1">
                    <div className="border-b border-dashed border-hero-blue-700 group-hover:border-solid">
                      {invoice.invoice_id}
                    </div>

                    <div className="h-4 w-4 [&_path]:fill-hero-blue-700 hidden group-hover:block">
                      <IconArrowNarrowRight />
                    </div>
                  </div>

                  <div>{formatPence(invoice.total_amount_in_pence)}</div>
                </div>

                <div className="flex justify-between items-center">
                  <div>
                    <div className="text-hero-blue-400 text-sm leading-5 mb-1">
                      {formatISODateTime(
                        invoice.created_at,
                        "short-month-name"
                      )}
                    </div>

                    <StatusBadge
                      resource="legacy-invoice"
                      status={invoice.status}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {pagination.total_pages > 1 && (
            <div className="flex justify-end">
              <ButtonGroup>
                <Button
                  size="sm"
                  variant="white"
                  disabled={pagination.current_page <= 1}
                  onClick={handlePagination(pagination.current_page - 1)}
                >
                  {translate("base.previous")}
                </Button>

                <Button
                  size="sm"
                  variant="white"
                  disabled={pagination.current_page >= pagination.total_pages}
                  onClick={handlePagination(pagination.current_page + 1)}
                >
                  {translate("base.next")}
                </Button>
              </ButtonGroup>
            </div>
          )}
        </>
      )}
    </div>
  );
}
