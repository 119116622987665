import { Button, IconSearch, Spinner, Table } from "@herohealthsoftware/ui";
import { includes, indexOf } from "lodash";
import React, { useEffect, useState } from "react";

import useStripeSearch, {
  StripeSearchType,
} from "../../../../hooks/useStripeSearch";
import { formatISODateTime } from "../../../../lib/datetime";
import { translate } from "../../../../lib/i18n";
import { formatPence, formatProductPrice } from "../../../../lib/money";
import { Invoice, Product } from "../../../../lib/types";
import PaginationButtons from "../PaginationButtons";
import StatusBadge from "../StatusBadge";

type ResourceTableProps = {
  resource: StripeSearchType;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  stripe_dashboard_url: string;
};

export default function ResourceTable(props: ResourceTableProps) {
  // The Stripe API uses cursor based pagination. We store the cursors
  // in state so that we can easily go back and forth between pages.
  const [cursors, setCursors] = useState([]);
  const [query, setQuery] = useState("");
  const { search, results, setResults, total } = useStripeSearch(
    props.resource
  );

  // `sections` is defined at the bottom of this file
  const section = sections(props)[props.resource];

  const handleSearch = async (query: string, cursor?: string) => {
    props.setLoading(true);

    const callback = (response) => {
      setResults(response.data);

      if (includes(cursors, response.cursor)) {
        // User went back to a previous page, remove cursors after that page
        setCursors(cursors.slice(0, indexOf(cursors, response.cursor) + 1));
      } else {
        // User went forward to a new page, add the cursor
        setCursors([...cursors, response.cursor]);
      }

      props.setLoading(false);
    };

    if (query === "") {
      search("*", { min: 0, cursor, callback });
    } else {
      search(query, { min: 3, cursor, callback });
    }
  };

  const handleResetPage = () => {
    handleSearch(query);
  };

  const handlePreviousPage = () => {
    // index -3 because -2 is the current page
    handleSearch(query, cursors[cursors.length - 3]);
  };

  const handleNextPage = () => {
    handleSearch(query, cursors[cursors.length - 1]);
  };

  useEffect(() => {
    // Reset cursors when switching resources or searching
    setCursors([]);
  }, [props.resource, query]);

  useEffect(() => {
    // Reset query when switching resources
    setQuery("");
  }, [props.resource]);

  useEffect(() => {
    // No cursor means we have't loaded the first page yet
    if (cursors.length === 0) {
      handleSearch(query);
    }
  }, [cursors]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row justify-between gap-2 mb-2">
        {section.searchable && (
          <div className="relative md:w-1/2">
            <input
              type="search"
              onChange={(event) => setQuery(event.target.value)}
              className="form-control !pr-8"
              placeholder={section.searchPlaceholder}
            />

            <div className="w-5 h-5 z-20 absolute top-2 right-2 [&_path]:fill-hero-blue-400">
              <IconSearch />
            </div>
          </div>
        )}

        {section.createButtonLink && (
          <Button
            variant="primary"
            href={section.createLink}
            target="_blank"
            className="w-fit"
          >
            <a href={section.createButtonLink} target="_blank">
              {section.createButtonText}
            </a>
          </Button>
        )}
      </div>

      {props.loading && (
        <div
          className="w-full h-64 flex flex-col items-center justify-center
                border border-hero-blue-200 rounded p-6"
        >
          <div className="h-8 w-8">
            <Spinner />
          </div>

          <div className="mt-2 font-medium">
            {section.loadingMessage}
            <>&hellip;</>
          </div>
        </div>
      )}

      {!props.loading && total > 0 && (
        <div className="flex flex-col gap-4">
          <Table
            className="w-full"
            headers={section.headers}
            rows={results.map(section.rowMapper)}
          />

          <PaginationButtons
            onReset={handleResetPage}
            onPrevious={handlePreviousPage}
            onNext={handleNextPage}
            disabled={{
              reset: cursors.length <= 1,
              previous: cursors.length <= 1,
              next: cursors[cursors.length - 1] === null,
            }}
          />
        </div>
      )}

      {!props.loading && total === 0 && (
        <div
          className="w-full h-64 flex flex-col items-center justify-center
              border border-hero-blue-200 rounded p-6"
        >
          <div className="mt-2 font-medium text-hero-blue-400">
            {section.noResultsMessage}
          </div>
        </div>
      )}
    </div>
  );
}

const sections = (props: ResourceTableProps) => {
  return {
    invoice: {
      searchable: false,
      rowMapper: (invoice: Invoice) => ({
        values: {
          created: formatISODateTime(invoice.created_at, "short"),
          invoice_number: invoice.stripe_invoice_number,
          status: (
            <StatusBadge
              resource="invoice"
              status={invoice.stripe_status}
              className="m-0 py-0.5"
            />
          ),
          amount: formatPence(invoice.stripe_amount_due),
          customer: invoice.customer_name || <>&mdash;</>,
          patient: invoice.patient_name_std || <>&mdash;</>,
        },
      }),
      ...translate("partners.stripe.tables.invoice"),
    },
    product: {
      searchable: true,
      createButtonLink: `${props.stripe_dashboard_url}/products/create`,
      rowMapper: (product: Product) => {
        return {
          values: {
            name: (
              <a
                href={`${props.stripe_dashboard_url}/products/${product.stripe_id}`}
                target="_blank"
              >
                <div>{product.name}</div>
                <div className="text-xs text-hero-blue-400 leading-4 font-medium">
                  {formatProductPrice(product)}
                </div>
              </a>
            ),
            created: formatISODateTime(product.created_at, "short"),
            updated: formatISODateTime(product.updated_at, "short"),
          },
        };
      },
      ...translate("partners.stripe.tables.product"),
    },
  };
};
