import { cva } from "class-variance-authority";
import React from "react";

type IntegrationSidebarProps = {
  name: string;
  logo: string;
  position: "fixed" | "relative";
  onClose: () => void;
  children: React.ReactNode;
};

export default function IntegrationSidebar(props: IntegrationSidebarProps) {
  const className = cva(
    [
      "w-[min-content]",
      "pt-12",
      "h-full",
      "border-l",
      "border-hero-blue-300",
      "shadow-lg",
    ],
    {
      variants: {
        position: {
          fixed: "fixed top-0 right-0 z-30 h-full bg-white text-hero-blue-700",
          relative: "",
        },
      },
    }
  );

  return (
    <div className={className({ position: props.position })}>
      <div
        className="min-w-[400px] flex items-center justify-between h-14 px-6 py-4
          bg-hero-blue-100 border-b border-hero-blue-200"
      >
        <div className="flex items-center">
          <img src={props.logo} alt={props.name} className="w-6 mr-2" />
          <h4 className="font-bold text-lg m-0">{props.name}</h4>
        </div>

        <div className="flex items-center">
          <i
            className="icon-close cursor-pointer text-hero-blue-700 h-3"
            onClick={props.onClose}
          ></i>
        </div>
      </div>

      <div>{props.children}</div>
    </div>
  );
}
