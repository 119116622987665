import {
  ErrorSummary,
  IconExternalLink,
  Link,
  Spinner,
  Typography,
} from "@herohealthsoftware/ui";
import React, { useEffect, useState } from "react";
import { formatISODateTime } from "../../../../lib/datetime";
import Hero from "../../../../lib/hero";
import { translate } from "../../../../lib/i18n";
import {
  formatBillingPeriodForSubscription,
  formatIntervalForSubscripiton,
} from "../../../../lib/stripe";
import { MembershipPolicy, StripeSettings } from "../../../../lib/types";
import * as Routes from "../../../../routes";
import IntegrationSidebar from "../../../shared/IntegrationSidebar";
import CustomerEntityCard from "../CustomerEntityCard";
import StatusBadge from "../StatusBadge";
import MembershipPolicyMembers from "./MembershipPolicyMembers";

type MembershipPolicySidebarProps = {
  membershipPolicy: Partial<MembershipPolicy>;
  onClose?: (reload: boolean) => void;
  handleBackClick?: () => void;
  settings: StripeSettings;
  sidebar: boolean;
};

export default function MembershipPolicySidebar(
  props: MembershipPolicySidebarProps
) {
  const [membershipPolicy, setMembershipPolicy] = useState<MembershipPolicy>();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Array<{ message: string }>>([]);

  const fetchMembershipPolicy = async () => {
    setLoading(true);

    const response = await Hero.fetch(
      Routes.partners_stripe_admins_membership_policy_path(
        props.membershipPolicy.id
      )
    );

    try {
      if (response.ok) {
        const data = await response.json();
        setMembershipPolicy(data);
      } else {
        const data = await response.json();
        setErrors(data.errors.map((error: string) => ({ message: error })));
      }
    } catch (error) {
      console.error(error);
      setErrors([{ message: translate("base.unexpectedError") }]);
    } finally {
      setLoading(false);
    }
  };

  const Container = ({ children }: { children: React.ReactNode }) => {
    if (!props.sidebar) return <React.Fragment children={children} />;

    return (
      <IntegrationSidebar
        name={translate("partners.stripe.stripe")}
        logo={props.settings.logo}
        position="fixed"
        onClose={() => props.onClose && props.onClose(true)}
      >
        {children}
      </IntegrationSidebar>
    );
  };
  props.sidebar ? IntegrationSidebar : React.Fragment;

  useEffect(() => {
    if (props.membershipPolicy.id) {
      fetchMembershipPolicy();
    }
  }, [props.membershipPolicy]);

  return (
    <Container>
      <div
        className="w-[600px] h-[calc(100vh-100px)] overflow-y-scroll
          px-6 py-5 flex flex-col gap-6"
      >
        {loading && (
          <div
            className="flex flex-col justify-center items-center p-6
            border border-hero-blue-200 rounded-lg text-hero-blue-700"
          >
            <Spinner className="w-8 h-8 mb-2 fill-hero-blue-700" />

            <div className="leading-6 font-medium">
              {translate("partners.stripe.loadingStripeMembershipPolicy")}
            </div>
          </div>
        )}

        {!loading && errors.length > 0 && <ErrorSummary items={errors} />}

        {!loading && membershipPolicy && (
          <>
            <div className="flex flex-col gap-1">
              {props.handleBackClick && (
                <div
                  className="font-bold text-xs cursor-pointer mb-1"
                  onClick={props.handleBackClick}
                >
                  ←{" "}
                  <span className="border-b border-dashed border-hero-blue-700">
                    {translate("base.back")}
                  </span>
                </div>
              )}

              <Typography size="xl" weight="bold">
                <h3>
                  {membershipPolicy.membership_scheme?.name ||
                    translate("partners.stripe.noMembershipScheme")}
                </h3>
              </Typography>

              <dl className="text-sm font-normal flex gap-x-2 text-hero-blue-500 mb-0">
                <dt>{translate("partners.stripe.started")}</dt>
                <dd className="mr-1 mb-0">
                  {membershipPolicy.starts_on ? (
                    formatISODateTime(
                      membershipPolicy.starts_on,
                      "short-month-name-date"
                    )
                  ) : (
                    <>&mdash;</>
                  )}
                </dd>

                <dt>{translate("partners.stripe.nextInvoice")}</dt>
                <dd className="mr-1 mb-0">
                  {membershipPolicy.subscription?.next_invoice_on ? (
                    formatISODateTime(
                      membershipPolicy.subscription.next_invoice_on,
                      "short-month-name-date"
                    )
                  ) : (
                    <>&mdash;</>
                  )}
                </dd>
              </dl>

              <StatusBadge
                resource="membership_policy"
                status={membershipPolicy.status}
              />

              <Link variant="primary">
                <a
                  href={`${props.settings.dashboard_url}/subscriptions/${membershipPolicy.subscription.stripe_id}`}
                  className="flex items-center gap-1"
                  target="_blank"
                >
                  <div>
                    {translate(
                      "partners.stripe.goToSubscriptionInStripeDashboard"
                    )}
                  </div>
                  <div className="h-4 w-4 [&_path]:fill-hero-primary-700">
                    <IconExternalLink />
                  </div>
                </a>
              </Link>
            </div>

            {membershipPolicy.subscription && (
              <dl>
                <dt className="mt-2">
                  {translate("partners.stripe.currentBillingPeriod")}
                </dt>
                <dd>
                  {formatBillingPeriodForSubscription(
                    membershipPolicy.subscription
                  ) || <>&mdash;</>}
                </dd>

                <dt className="mt-2">{translate("partners.stripe.renewal")}</dt>
                <dd>
                  {formatIntervalForSubscripiton(membershipPolicy.subscription)}
                </dd>

                <dt className="mt-2">{translate("partners.stripe.product")}</dt>
                <dd>{membershipPolicy.subscription.plan.product.name}</dd>
              </dl>
            )}

            {membershipPolicy.customer && (
              <div className="border border-hero-blue-200 rounded-lg py-5 px-6">
                <CustomerEntityCard customer={membershipPolicy.customer} />
              </div>
            )}

            <MembershipPolicyMembers
              membershipPolicy={membershipPolicy}
              settings={props.settings}
            />
          </>
        )}
      </div>
    </Container>
  );
}
